import "./Setting.scss";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import queryString from "query-string";
import { useSelector, useDispatch } from "react-redux";
import {
  projectSetting,
  removeProjectSettingErrMsg,
  removeProjectSettingMsg,
} from "../../../Redux/SettingManagementSlice";
import { toast } from "react-toastify";

function Setting(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const { projectSettingErrMsg, projectSettingMsg } = useSelector(
    (state) => state?.SettingManagementSlice
  );
  let project_id = queryString?.parse(history?.location?.search)?.id || 0;
  const [inputBoxData, setInputBoxData] = useState({
    setting_name: "",
    setting_value: "",
  });
  const [inputErrorData, setInputErrorData] = useState({
    setting_name: "",
    setting_value: "",
  });

  const handleChange = (e) => {
    let temp = { ...inputBoxData };
    temp[e.target.name] = e.target.value;
    setInputBoxData(temp);
  };
  const handleErrorRemove = (e) => {
    let temp = { ...inputErrorData };
    temp[e.target.name] = "";
    setInputErrorData(temp);
  };

    const validate = () => {
    let isError = false;
    let temp = { ...inputErrorData };
    if (inputBoxData?.setting_name === "") {
      temp.setting_name = "Setting Name can not be empty";
      isError = true;
    }
    if (inputBoxData?.setting_value === "") {
      temp.setting_value = "Setting value can not be empty";
      isError = true;
    }
    setInputErrorData(temp);
    if (isError) window.scroll(0, 0);
    return isError;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var formData = new FormData();
    let isError = validate();
    if (!isError) {
      Object.entries(inputBoxData).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("project_code",project_id);
      dispatch(projectSetting(formData))
    }
  };

  useEffect(() => {
    if(projectSettingMsg){
      toast.success(projectSettingMsg);
    }
    if(projectSettingErrMsg){
      toast.error(projectSettingErrMsg);
    }
    dispatch(removeProjectSettingErrMsg())
    dispatch(removeProjectSettingMsg())
   
  }, [projectSettingMsg,projectSettingErrMsg])




  return (
    <div>
      <div className="settingOuter">
        <h2>Project Settings</h2>
        <form onSubmit={handleSubmit} autocomplete="off">
          <div className="formInner">
            <div className="form-group py-1">
              <label>Setting Name</label>
              <div className="fieldWrapper">
                <input
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.vault_url}
                  type="text"
                  name="setting_name"
                  placeholder="Enter setting name"
                />
                {!!inputErrorData?.setting_name && (
                  <div className="error_danger">
                    {inputErrorData?.setting_name}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group py-1">
              <label>Setting Value</label>
              <div className="fieldWrapper">
                <input
                  type="text"
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.setting_value}
                  name="setting_value"
                  placeholder="Enter setting value"
                />
                {!!inputErrorData?.setting_value && (
                  <div className="error_danger">{inputErrorData?.setting_value}</div>
                )}
              </div>
            </div>

            <div className="btnwrap">
              <button type="submit" className="btn">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Setting;
