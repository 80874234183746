import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';

import {
  fetchTestSuite,
  removeUpdateTestSuiteMsg,
  removeUpdateTestSuiteErrMsg,
  updateTestsuite,
  removeTestCaseMsg,
  deleteTestSuite,
} from "../../../Redux/AdminTestSuiteSlice";
import { toast } from "react-toastify";
import SweetAlertComponent from "../../../Share_Module/SweetAlertComponent/SweetAlertComponent";
import ReactPaginate from "react-paginate";
import { imagePath } from "./../../../API/index";

function TestSuits() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isDelete, setIsdelete] = useState(false);
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [currantP, setCurrantpage] = useState(1);
  const [query, setQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sort_field, setSortField] = useState("suite_name");

  const {
    status,
    totalPage,
    currantPage,
    TestSuiteList,
    updateTestSuiteMsg,
    updateTestSuiteErrMsg,
    deleteTestCaseMsg,
  } = useSelector((state) => state?.AdminTestSuiteSlice);
  useEffect(() => {
    if (totalPage) {
      setPageCount(totalPage);
    }
    if (currantPage) {
      setCurrantpage(currantPage);
    }
  }, [totalPage, currantPage]);

  const deleteProject1 = (name, id) => {
    // console.log(name)
    setUserName(name);
    setUserId(id);
    setIsdelete(true);
  };

  const handleCloneConfirm = () => {
    if (userId !== "") {
      dispatch(deleteTestSuite({  id: userId, 
        page: currantP,
        sort_field: sort_field,
        sort_order: sortOrder, }));
    }
    setUserName("");
    setUserId("");
    setIsdelete(false);
  };

  useEffect(() => {
    if (deleteTestCaseMsg !== "") {
      toast.success(deleteTestCaseMsg);
      dispatch(removeTestCaseMsg());
    }
  }, [dispatch, deleteTestCaseMsg]);

  const handlePageClick = ({ selected }) => {
    setCurrantpage(selected + 1);
    dispatch(fetchTestSuite( {
      page: selected + 1,
      query: query,
      sort_field: sort_field,
      sort_order: sortOrder,
    }));
   
  };

  const handleSearch = () => {
    // setCurrantpage(1)
    // dispatch(projectListing({page:1,query:query}));

    if (query == "") {
      setCurrantpage(currantPage);
      dispatch(fetchTestSuite({
        page: currantPage,
        query: query,
        sort_field: sort_field,
        sort_order: sortOrder,
      }));
      
    } else {
      setCurrantpage(1);
      dispatch(fetchTestSuite( {
        page: 1,
        query: query,
        sort_field: sort_field,
        sort_order: sortOrder,
      }));
     
    }
  };
  useEffect(() => {
    dispatch(fetchTestSuite( {
      page: 1,
      query: query,
      sort_field: sort_field,
      sort_order: sortOrder,
    }));
  }, [dispatch]);

  const short = (sort_field1) => {
    setSortField(sort_field1)
    if (sortOrder === "asc") {
      setSortOrder("desc");
      dispatch(
        fetchTestSuite({
          page: 1,
          query: query,
          sort_field: sort_field1,
          sort_order: "desc",
        })
      );
    } else {
      setSortOrder("asc");
      dispatch(
        fetchTestSuite({
          page: 1,
          query: query,
          sort_field: sort_field1,
          sort_order: "asc",
        })
      );
    }
  };
  const statuschange = (id, status) => {
    const data = {
      page: currantPage,
      status: status,
      id: id,
      sort_field:sort_field,
      sort_order:sortOrder
    };
    dispatch(updateTestsuite(data));
    // dispatch(updateTestsuite(formData));
  };

  useEffect(() => {
    if (updateTestSuiteMsg) {
      toast.success("Status Change Successful");
    }
    if (updateTestSuiteErrMsg) {
      toast.error("Status Did Not change!");
    }
    dispatch(removeUpdateTestSuiteMsg());
    dispatch(removeUpdateTestSuiteErrMsg());
  }, [updateTestSuiteMsg, updateTestSuiteErrMsg]);

  return (
    <div>
      <div className="addbtn">
        <Link to="admin-add-testsuite" className="btn">
          Add Test Suite
        </Link>
      </div>
      <div className="searchFilter">
        <div className="searchBar">
          <input
            type="text"
            placeholder="Search"
            value={query}
            onChange={(e) => {
              setQuery(e?.target?.value);
            }}
            onKeyPress={(event) => event.key === "Enter" && handleSearch()}
          />
          <button
            type="button"
            className="btn"
            onClick={(event) => handleSearch()}
          >
            <img src="images/search.svg" alt="" />
          </button>
        </div>
        {/* <div className="filtersec">
          <select name="" id="">
            <option value="">Option 1</option>
            <option value="">Option 2</option>
            <option value="">Option 3</option>
          </select>
        </div> */}
      </div>
      <div className="dataSection">
        <div className="table-responsive">
          <table>
            <thead>
              <tr>
                <th>
                  Test suite{" "}
                  <button type="button" onClick={() => short("suite_name")}>
                    <img src="images/sorting.svg" alt="" />
                  </button>
                </th>
                <th>
                  Project Name{" "}
                  <button type="button" onClick={() => short("project_name")}>
                    <img src="images/sorting.svg" alt="" />
                  </button>
                </th>
                <th>
                  Organization Name{" "}
                  <button type="button" onClick={() => short("org_name")}>
                    <img src="images/sorting.svg" alt="" />
                  </button>
                </th>

                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {status === "loading" ? (
                [1,1,1,1,1,1]?.map((obj,i)=>
                <tr className="" key={i}>
                <td >
                  <Skeleton variant="text" width={"99%"} height={30} />
                </td>
                <td >
                  <Skeleton variant="text" width={"99%"} height={30} />
                </td>
                <td >
                  <Skeleton variant="text" width={"99%"} height={30} />
                </td>
                <td >
                  <Skeleton variant="text" width={"99%"} height={30} />
                </td>
               
               
              </tr>
               )
              ) : TestSuiteList?.length === 0 ? (
                <td colSpan="3">Test Suite not found!</td>
              ) : (
                TestSuiteList?.filter((gs) => gs.isDeleted === false)?.map(
                  (testSuite, key) => (
                    <tr key={key}>
                      <td>
                        {testSuite?.suite_name}
                        &nbsp;
                        {testSuite?.status == "Active" ? (
                          <span className="badge bg-success ">Active</span>
                        ) : (
                          <span className="badge bg-danger ">Inactive</span>
                        )}
                      </td>
                      <td>{testSuite?.project_data?.project_name}</td>
                      <td>{testSuite?.org_data?.org_name}</td>
                      <td>
                        {" "}
                        <Link to={`admin-edit-testsuite?id=${testSuite?._id}`}>
                          <i
                            className="fas fa-edit cursor-pointer"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Edit Test Suite"
                          ></i>
                        </Link>{" "}
                        &nbsp;&nbsp;{" "}
                        <i
                          className="fas fa-trash-alt cursor-pointer"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Delete Test Suite"
                          onClick={() => {
                            deleteProject1(
                              testSuite?.suite_name,
                              testSuite?._id
                            );
                          }}
                        ></i>
                        {testSuite?.status == "Active" ? (
                          <>
                            &nbsp;&nbsp;{" "}
                            <i
                              className="fas fa-toggle-off cursor-pointer"
                              style={{ fontSize: "15px" }}
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Inactive"
                              onClick={() => {
                                statuschange(testSuite?._id, "Inactive");
                              }}
                            ></i>
                          </>
                        ) : (
                          <>
                            &nbsp;&nbsp;{" "}
                            <i
                              className="fas fa-toggle-on cursor-pointer"
                              style={{ fontSize: "15px" }}
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Active"
                              onClick={() => {
                                statuschange(testSuite?._id, "Active");
                              }}
                            ></i>
                          </>
                        )}
                      </td>
                    </tr>
                  )
                )
              )}
            </tbody>
          </table>
        </div>
        <nav className="paginations" aria-label="Page navigation example">
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            pageCount={pageCount}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
            forcePage={currantPage-1}
          />
        </nav>
      </div>
      {isDelete && (
        <SweetAlertComponent
          confirm={handleCloneConfirm}
          cancle={() => setIsdelete(false)}
          title={"Are you sure?"}
          subtitle={`you want to Delete the Organization ${userName}`}
          type="danger"
        />
      )}
    </div>
  );
}

export default TestSuits;
