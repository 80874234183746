import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiHelperFunction } from "../helpers/apiHelper";
import { useSelector, useDispatch } from "react-redux";

export const createproject = createAsyncThunk(
    "project/createproject",
    async (formData) => {
      const response = await ApiHelperFunction({
        urlPath: "api/project/insert",
        method: "post",
        formData: formData,
      });
      let resData = response?.data;
      return resData;
    }
);
export const projectListing = createAsyncThunk(
    "project/projectListing",
    async (formData) => {
      const response = await ApiHelperFunction({
        urlPath: "api/project/list-advance",
        method: "post",
        formData: formData,
      });
      let resData = response?.data;
      return resData;
    }
);
export const singleproject = createAsyncThunk(
  "project/singleproject",
  async (id) => {
    const response = await ApiHelperFunction({
      urlPath: `api/project/details/${id}`,
      method: "get",
      // formData: formData,
    });
    let resData = response?.data;
    return resData;
  }
);

export const updateProject = createAsyncThunk(
  "project/updateProject",
  async (formData) => {
    const response = await ApiHelperFunction({
      urlPath: `api/project/update/${formData?.get("id")}`,
      method: "post",
      formData: formData,
    });
    let resData = response?.data;
    // console.log(resData)
    return resData;
  }
);

export const deleteProject = createAsyncThunk(
  "project/deleteProject",
  async (formData) => {
    // console.log(resData)
    const response = await ApiHelperFunction({
      urlPath: `api/project/delete`,
      method: "post",
      formData: formData,
    });
    let resData = response?.data;
    
    return resData;
  }
);


const initialState = {
    status: "idle",
    createProjectMsg:"",
    createProjectErrMsg:"",
    projectList:[],
    totalPage:"",
    currantPage:"",
    singleprojectlist:"",
    projectUpdateMsg:"",
    projectUpdateErrMsg:"",
    projectDeleteMsg:"",
    projectDeleteErrMsg:"",
};

export const mainSlice = createSlice({
    name: "project",
    initialState,
    reducers: {
      removeAll: (state) => {
        state = initialState;
      },
      removeCreateProjectMsg: (state) => {
        state.createProjectMsg = "";
      },
      removeCreateProjectErrMsg: (state) => {
        state.createProjectErrMsg = "";
      },
      removeUpdateProjectMsg: (state) => {
        state.projectUpdateMsg = "";
      },
      removeUpdateProjectErrMsg: (state) => {
        state.projectUpdateErrMsg = "";
      },
      removeDeleteProjectMsg: (state) => {
        state.projectDeleteMsg = "";
     
      },
      removeDeleteProjectErrMsg: (state) => {
        state.projectDeleteErrMsg = "";
      },

    },
extraReducers: (builder) => {
      builder
        .addCase(createproject.pending, (state) => {
            state.status = "loading";
          })
        .addCase(createproject.fulfilled, (state, action) => {
            state.status = "idle";
            if (action.payload != undefined) {
              if (action.payload.status === 200) {
                state.createProjectMsg = action.payload.message;
              } else {
                state.createProjectErrMsg = action.payload.message;
              }
            } else {
              state.createProjectErrMsg = "Something went to wrong !";
            }
        })
        .addCase(projectListing.pending, (state) => {
            state.status = "loading";
        })
        .addCase(projectListing.fulfilled, (state, action) => {
            state.status = "idle";
            if (action.payload != undefined) {
              if (action.payload.status === 200) {
                state.projectList = action?.payload?.data?.docs;
                state.currantPage = action?.payload?.data?.page;
                state.totalPage = action?.payload?.data?.pages;
              } else {
                state.createProjectErrMsg = action.payload.message;
              }
            } else {
              state.createProjectErrMsg = "Something went to wrong !";
            }
        })

      .addCase(singleproject.pending, (state) => {
          state.status = "loading";
      })
      .addCase(singleproject.fulfilled, (state, action) => {
          state.status = "idle";
          if (action.payload != undefined) {
            if (action.payload.status === 200) {
              state.singleprojectlist = action?.payload?.data;
            } else {
              state.createProjectErrMsg = action.payload.message;
            }
          } else {
            state.createProjectErrMsg = "Something went to wrong !";
          }
      })

    .addCase(updateProject.pending, (state) => {
        state.status = "loading";
    })
    .addCase(updateProject.fulfilled, (state, action) => {
        state.status = "idle";



        console.log(action.payload)
        if (action.payload != undefined) {
          if (action.payload.status === 200) {
            state.projectUpdateMsg = action?.payload?.message;
            state.projectList = action?.payload?.inPageData?.docs;
            state.currantPage = action?.payload?.inPageData?.page;
            state.totalPage = action?.payload?.inPageData?.pages;
           
          } else {
            state.projectUpdateErrMsg = action.payload.message;
          }
        } else {
          state.projectUpdateErrMsg = "Something went to wrong !";
        }
    })

    
  .addCase(deleteProject.pending, (state) => {
      state.status = "loading";
  })
  .addCase(deleteProject.fulfilled, (state, action) => {
      state.status = "idle";
      if (action.payload != undefined) {
        if (action.payload.status === 200) {
          state.projectDeleteMsg = action?.payload?.message;
          state.projectList = action?.payload?.data?.docs;
          state.currantPage = action?.payload?.data?.page;
          state.totalPage = action?.payload?.data?.pages;
        } else {
          state.projectUpdateErrMsg = action.payload.message;
        }
      } else {
        state.projectUpdateErrMsg = "Something went to wrong !";
      }
  })


    



    },
  });

export const { removeAll,removeCreateProjectMsg,removeCreateProjectErrMsg,removeUpdateProjectMsg,removeUpdateProjectErrMsg,removeDeleteProjectMsg,removeDeleteProjectErrMsg} = mainSlice.actions;
export default mainSlice.reducer;