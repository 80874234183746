import React, { useState, useEffect, useRef } from "react";
import "./Test-Suites.scss";
import Accordion from "react-bootstrap/Accordion";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import queryString from "query-string";
import Tooltip from "@mui/material/Tooltip";
import {
  testSuitesFetch,
  testCaseFetch,
  clearTestCasesErrorMsg,
  clearTestSuiteErrorMsg,
  locstroe,
  clearlocstroe,
  startJenkinsBuild,
  stopJenkinsBuild,
} from "../../Redux/TestSuitesSlice";
import TestSuitesSkiliton from "./TestSuitesSkiliton";
import AudioPlayer from "material-ui-audio-player";
import { imagePath } from "./../../API/index";
import "./song.mp3";
function TestSuites() {
  let audioref = useRef();
  const [progress, setProgress] = React.useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentTime, setCurrentTime] = useState(0);
  const [duretion, setDuretion] = useState(0);
  const [play, setPlay] = useState(true);
  const { status, testSuite, testCase, testSuiteErrMsg, localstroage } =
    useSelector((state) => state?.TestSuites);
  const { userData } = useSelector((state) => state?.UserAuthenticationSlice);
  let project_code =
    queryString?.parse(history?.location?.search)?.project_code || 0;
  if (project_code === 0) {
    history.push(`/`);
  }
  useEffect(() => {
    dispatch(testSuitesFetch(project_code));
    return () => {
      dispatch(clearTestSuiteErrorMsg());
    };
  }, [dispatch]);
  useEffect(() => {
    dispatch(testCaseFetch());
    return () => {
      dispatch(clearTestCasesErrorMsg());
    };
  }, [dispatch]);

  const [hide, toggleShow] = React.useState(0);
  const [peramiter, setPeramiter] = React.useState(0);

  const showHide = (first) => {
    if (hide == first) {
      toggleShow(first);
      if (peramiter == 0) {
        setPeramiter(1);
      } else {
        setPeramiter(0);
      }
    } else {
      toggleShow(first);
      setPeramiter(1);
    }
  };

  const playAudio = (id) => {
    setPlay(false);

    // var pause = document.getElementById(`pause-${id}`);
    // var clickBtn = document.getElementsByClassName('click')[0];
    // console.log(clickBtn)
    // clickBtn.addEventListener('click', function(event) {
    //   pause.disabled = !pause.disabled;
    // });

    var track = document.getElementById(`track-${id}`);
    var progressed = document.getElementById(`progressed-${id}`);
    var pro = document.getElementById(`pro-${id}`);
    let testCaseId = id;
    let cTime = track.currentTime;
    let dTime = track.duration;
    let userId = userData?._id;
    console.log(cTime);
    let record = JSON.parse(localStorage.getItem("recordingDetails"));
    let findArray = record?.find(
      (gs) => gs?.testCaseId === testCaseId && gs?.userId === userId
    );
    if (findArray != undefined) {
      track.currentTime = findArray?.cTime;
    }

    track.ontimeupdate = function () {
      progressed.style.width =
        Math.floor((track.currentTime * 100) / track.duration) + "%";
      try {
        document.getElementById(`currentTime-${id}`).innerHTML =
          convert(track.currentTime) || 0;
        document.getElementById(`duration-${id}`).innerHTML =
          convert(track.duration) || 0;
      } catch (error) {}

      if (track.currentTime === track.duration) {
        setPlay(true);
        let testCaseId = id;
        let cTime = 0;
        let dTime = track.duration;
        let userId = userData?._id;
        let record = JSON.parse(localStorage.getItem("recordingDetails")) || [];

        let index = record?.findIndex(
          (gs) => gs?.testCaseId === testCaseId && gs?.userId === userId
        );
        if (index > -1) {
          record.splice(index, 1, { testCaseId, cTime, dTime, userId });
          localStorage.setItem("recordingDetails", JSON.stringify(record));
        } else {
          record.push({ testCaseId, cTime, dTime, userId });
          localStorage.setItem("recordingDetails", JSON.stringify(record));
        }
      }
    };
    pro.onclick = function (e) {
      track.currentTime = (e.offsetX / pro.offsetWidth) * track.duration;
    };
    track?.play();

    //call jenkins api for create a build
    dispatch(startJenkinsBuild({}));
  };

  const pauseAudio = (id) => {
    setPlay(true);
    var track = document.getElementById(`track-${id}`);
    track?.pause();
    let testCaseId = id;
    let cTime = track.currentTime;
    let dTime = track.duration;
    let userId = userData?._id;

    let record = JSON.parse(localStorage.getItem("recordingDetails")) || [];
    let index = record?.findIndex(
      (gs) => gs?.testCaseId === testCaseId && gs?.userId === userId
    );
    if (index > -1) {
      record.splice(index, 1, { testCaseId, cTime, dTime, userId });
      localStorage.setItem("recordingDetails", JSON.stringify(record));
    } else {
      record.push({ testCaseId, cTime, dTime, userId });
      localStorage.setItem("recordingDetails", JSON.stringify(record));
    }
    //call jenkins api for create a build
    dispatch(stopJenkinsBuild({}));
  };

  useEffect(() => {
    let record = JSON.parse(localStorage.getItem("recordingDetails"));
    dispatch(locstroe(record));
  }, [dispatch]);

  useEffect(() => {
    if (localstroage && localstroage?.length > 0) {
      // JSON.parse(localStorage.getItem('recordingDetails')).forEach(element => {
      // var track = document.getElementById(`track-${element?.testCaseId}`);
      // var progressed = document.getElementById(`progressed-${element?.testCaseId}`);
      // progressed.style.width = Math.floor((element?.cTime * 100) / element?.dTime) + "%";
      //  console.log("ggg",progressed)
      //  console.log("ggg",element?.cTime)
      //  console.log("Sam1:",progressed)
      //   dispatch(clearlocstroe());
      // });
    }
  }, []);

  const convert = (value) => {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
    let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    if (hours == 0) {
      return minutes + ":" + seconds; // Return is HH : MM : SS
    } else {
      return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
    }
  };

  return (
    <div>
      <div className="AccordionOuter">
        <Accordion defaultActiveKey={0}>
          {status === "loading" ? (
            <TestSuitesSkiliton />
          ) : testSuite.filter(
              (pro) => pro?.isDeleted === false && pro?.status === "Active"
            ).length === 0 ? (
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="projectItem">
                  {/* <figure><img src="images/projectic2.svg" alt="" /></figure> */}
                  <h2>Test Suites not found!</h2>
                </div>
              </div>
            </div>
          ) : (
            testSuite
              .filter(
                (pro) => pro?.isDeleted === false && pro?.status === "Active"
              )
              .map((testSuits, index) => (
                <Accordion.Item eventKey={index} key={index}>
                  <Accordion.Header>{testSuits?.suite_name}</Accordion.Header>
                  <Accordion.Body>
                    <div className="testCaseLists">
                      <ul>
                        {testCase.filter(
                          (gs) =>
                            gs?.isDeleted === false &&
                            gs?.status === "Active" &&
                            gs.test_suite_code == testSuits._id
                        ).length == 0 ? (
                          <li>
                            <h3 style={{ textAlign: "center" }}>
                              Tast Cases not Found!{" "}
                            </h3>
                          </li>
                        ) : (
                          testCase
                            .filter(
                              (gs) =>
                                gs?.isDeleted === false &&
                                gs?.status === "Active" &&
                                gs.test_suite_code == testSuits._id
                            )
                            .map((tastCases, index) => (
                              <li key={tastCases._id}>
                                {tastCases.test_case_name}
                                <div className="playPauseRange">
                                  {/* <img src="images/playpausebtn.png" alt="" /> */}
                                  {/* <Box sx={{ width: "12%" }}>
                                      <LinearProgress
                                        variant="determinate"
                                        value={progress}
                                      /> */}
                                  {/* <input type="range" min="1" max="100" value="50"></input> */}
                                  {/* </Box> */}
                                  <audio
                                    ref={audioref}
                                    id={`track-${tastCases._id}`}
                                    controlslist="nodownload noplaybackrate"
                                  >
                                    <source
                                      src={`song.mp3`}
                                      // src={`${imagePath}/uploads/test_case/${tastCases?.audio}`}

                                      type="audio/ogg"
                                    />
                                  </audio>
                                  <span id={`currentTime-${tastCases._id}`}>
                                    00
                                  </span>
                                  /
                                  <span id={`duration-${tastCases._id}`}>
                                    00
                                  </span>{" "}
                                  &nbsp;
                                  <div
                                    className="progress_bar"
                                    id={`pro-${tastCases._id}`}
                                  >
                                    <div
                                      className="progressed"
                                      id={`progressed-${tastCases._id}`}
                                    ></div>
                                  </div>
                                  <Tooltip title="Play">
                                    <button
                                      type="button"
                                      className="pausebtn"
                                      onClick={() => playAudio(tastCases._id)}
                                      disabled={play ? false : true}
                                    >
                                      <img src="images/playic1.svg" alt="" />
                                    </button>
                                  </Tooltip>
                                  <Tooltip title="Pause">
                                    <button
                                      type="button"
                                      className="pausebtn"
                                      onClick={() => pauseAudio(tastCases._id)}
                                      id={`pause-${tastCases._id}`}
                                      // disabled={true}
                                    >
                                      <img src="images/pausebtn.svg" alt="" />
                                    </button>
                                  </Tooltip>
                                  <button type="button" className="pausebtn">
                                    <img src="images/reportic.svg" alt="" />
                                  </button>
                                  <button
                                    type="button"
                                    className="pausebtn"
                                    onClick={() => showHide(tastCases?._id)}
                                  >
                                    {" "}
                                    <img src="images/view-report.svg" alt="" />
                                  </button>
                                </div>
                                {hide === tastCases?._id && peramiter === 1 && (
                                  <div className="viewHistory">
                                    <h3>History</h3>
                                    <p>{tastCases?.content}</p>
                                  </div>
                                )}
                              </li>
                            ))
                        )}
                      </ul>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))
          )}
        </Accordion>
      </div>
    </div>
  );
}
export default TestSuites;
