/* Module used for Pop up Alert generation (Danger alert by-default) */
import "./SweetAlertComponent.scss";
import SweetAlert from "react-bootstrap-sweetalert";
function SweetAlertComponent({ confirm, cancle, title, subtitle, type }) {
  return (
    <SweetAlert
      title={title}
      onConfirm={confirm}
      // type="danger"
      type={type !== undefined ? type : "danger"}
      showCancel={true}
      // confirmBtnStyle={primary}
      // cancelBtnStyle={danger}
      onCancel={cancle}
    >
      <h5> {subtitle} </h5>
    </SweetAlert>
  );
}

export default SweetAlertComponent;
