import "./Help.scss";
import React from 'react'
function Help() {
  return (
    <div>
       <div className="helpPg">
         <h2>Help</h2>
         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris laoreet, nibh ut mattis mattis, metus nunc lacinia dolor, non lobortis nisl nibh eget lectus. Suspendisse at pellentesque mi. Aenean erat neque, blandit nec cursus eu, tempor sed ante. Nullam eleifend cursus diam at pulvinar. Duis sagittis nisl quis dapibus lacinia Vivamus velit lorem, cursus vel suscipit ut, tempus eget nibh. Sed et semper sem. Morbi sed auctor neque. Sed nec eros turpis. Pellentesque et neque a velit ultrices porta ac vel est. Nunc non justo pellentesque, tristique eros eget, aliquam nunc. Sed et eros at augue luctus ultrices. Nullam vulputate magna lacus, vitae fermentum mauris semper eget. Donec suscipit dolor rutrum arcu pellentesque dictum. Praesent vestibulum purus et tortor laoreet, vel cursus tellus ultrices. Etiam sed auctor diam</p>
         <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris laoreet, nibh ut mattis mattis, metus nunc lacinia dolor, non lobortis nisl nibh eget lectus. Suspendisse at pellentesque mi. Aenean erat neque, blandit nec cursus eu, tempor sed ante. Nullam eleifend cursus diam at pulvinar. Duis sagittis nisl quis dapibus lacinia Vivamus velit lorem, cursus vel suscipit ut, tempus eget nibh. Sed et semper sem. Morbi sed auctor neque. Sed nec eros turpis. Pellentesque et neque a velit ultrices porta ac vel est. Nunc non justo pellentesque, tristique eros eget, aliquam nunc. Sed et eros at augue luctus ultrices. Nullam vulputate magna lacus, vitae fermentum mauris semper eget.</p>
       </div>
    </div>
  );
}

export default Help;
