import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { ApiHelperFunction } from "../helpers/apiHelper";
export const fetchUser = createAsyncThunk(
    "user/fetchUser",
    async (formData) => {
        // console.log(formData)
      const response = await ApiHelperFunction({
        urlPath: "api/user/list-pagination",
        method: "post",
        formData:formData
      });
      let resData = response?.data;
      // console.log(resData)
      return resData;
    }
  );


  export const addUser = createAsyncThunk(
    "user/addUser",
    async (formData) => {
      const response = await ApiHelperFunction({
        urlPath: "api/user/add",
        method: "post",
        formData:formData
      });
      let resData = response?.data;
      return resData;
    }
  );

  export const deleteUser = createAsyncThunk(
    "user/deleteUser",
    async (formData) => {
      const response = await ApiHelperFunction({
        urlPath: `api/user/delete`,
        method: "post",
        formData:formData
      });
      let resData = response?.data;
      return resData;
    }
  );

  export const singleUserFetch = createAsyncThunk(
    "user/singleUserFetch",
    async (id) => {
      const response = await ApiHelperFunction({
        urlPath: `api/user/details/${id}`,
        method: "get",
        // formData:formData
      });
      let resData = response?.data;
      return resData;
    }
  );

  export const updateUser = createAsyncThunk(
    "user/updateUser",
    async (formData) => {
      const response = await ApiHelperFunction({
        urlPath: `api/user/update`,
        method: "post",
        formData:formData
      });
      let resData = response?.data;
      return resData;
    }
  );



const initialState = {
    status: "idle",
    userList: [],
    totalPage:"",
    currantPage:"",
    userErrorMsg:"",
    userAddMsg:"",
    userAddErrMsg:"",
    userDeletMsg:"",
    sinleuser:'',
    userUpdateMsg:"",
    userUpdateErrMsg:"",
};

export const mainSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
      removeAll: (state) => {
        state = initialState;
      },
      clearuserErrorMsg: (state) => {
        state.userErrorMsg = "";
      },
      clearaddUserMsg: (state) => {
        state.userAddMsg = "";
      },
      clearaddUserErrMsg: (state) => {
        state.userAddErrMsg = "";
      },
      clearUserDeleteMsg: (state) => {
        state.userDeletMsg = "";
      },
      clearupdateMsg: (state) => {
        state.userUpdateMsg = "";
      },
      clearupdateErrMsg: (state) => {
        state.userUpdateErrMsg = "";
      },
    },
    
    extraReducers: (builder) => {
      builder
        .addCase(fetchUser.pending, (state) => {
          state.status = "loading";
        })
        .addCase(fetchUser.fulfilled, (state, action) => {
          state.status = "idle";
          if (action.payload != undefined) {
            if (action.payload.status === 200) {
              state.userList = action?.payload?.data?.docs;
              state.currantPage = action?.payload?.data?.page;
              state.totalPage = action?.payload?.data?.pages;
            } else {
              state.userErrorMsg = action.payload.message;
            }
          } else {
            state.userErrorMsg = "Something went to wrong !";
          }
        })

        .addCase(deleteUser.pending, (state) => {
            state.status = "loading";
          })
          .addCase(deleteUser.fulfilled, (state, action) => {
            state.status = "idle";
            if (action.payload != undefined) {
              if (action.payload.status === 200) {
                 state.userDeletMsg=action.payload.message;
                 state.userList = action?.payload?.data?.docs;
                 state.currantPage = action?.payload?.data?.page;
                 state.totalPage = action?.payload?.data?.pages;
                // state.userList = action?.payload?.data?.docs;
                // state.currantPage = action?.payload?.data?.page;
                // state.totalPage = action?.payload?.data?.pages;
              } else {
                state.userErrorMsg = action.payload.message;
              }
            } else {
              state.userErrorMsg = "Something went to wrong !";
            }
          })
        .addCase(addUser.pending, (state) => {
            state.status = "loading";
        })
        .addCase(addUser.fulfilled, (state, action) => {
        state.status = "idle";
        // console.log(action.payload)
        if (action.payload != undefined) {
            if (action.payload.status === 200) {
              state.userAddMsg = action?.payload?.message;
            } else {
              state.userAddErrMsg = action?.payload?.message;
            }
        } else {
            state.userAddErrMsg = "Something went to wrong !";
        }
    })

    .addCase(singleUserFetch.pending, (state) => {
      state.status = "loading";
    })
    .addCase(singleUserFetch.fulfilled, (state, action) => {
      state.status = "idle";
      if (action.payload != undefined) {
        if (action.payload.status === 200) {
           state.sinleuser=action.payload.data
        } else {
          state.userErrorMsg = action.payload.message;
        }
      } else {
        state.userErrorMsg = "Something went to wrong !";
      }
    })

    .addCase(updateUser.pending, (state) => {
      state.status = "loading";
    })
    .addCase(updateUser.fulfilled, (state, action) => {
      state.status = "idle";
      if (action.payload != undefined) {
        if (action.payload.status === 200) {
           state.userUpdateMsg=action.payload.message;
        } else {
          state.userUpdateErrMsg = action.payload.message;
        }
      } else {
        state.userUpdateErrMsg = "Something went to wrong !";
      }
    })
    },
  });

  export const {
    removeAll,
    clearuserErrorMsg,
    clearaddUserMsg,
    clearaddUserErrMsg,
    clearUserDeleteMsg,
    clearupdateMsg,
    clearupdateErrMsg,
  } = mainSlice.actions;
  export default mainSlice.reducer;
  
  
  