import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { ApiHelperFunction } from "../helpers/apiHelper";
import { useSelector, useDispatch } from "react-redux";

export const projectSetting = createAsyncThunk(
    "setting/projectSetting",
    async (formData) => {
      const response = await ApiHelperFunction({
        urlPath: "api/project_settings/insert",
        method: "post",
        formData: formData,
      });
      let resData = response?.data;
      return resData;
    }
  );
  const initialState = {
    status: "idle",
    projectSettingErrMsg: "",
    projectSettingMsg: "",
  };
  export const mainSlice = createSlice({
    name: "setting",
    initialState,
    reducers: {
      removeAll: (state) => {
        state = initialState;
      },
      removeProjectSettingErrMsg: (state)=>{
        state.projectSettingErrMsg="";
      },
      removeProjectSettingMsg: (state)=>{
        state.projectSettingMsg="";
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(projectSetting.pending, (state) => {
          state.status = "loading";
        })
        .addCase(projectSetting.fulfilled, (state, action) => {
          state.status = "idle";
          if (action.payload != undefined) {
            if (action.payload.status === 200) {
              state.projectSettingMsg = action.payload.message;
            } else {
              state.projectSettingErrMsg = action.payload.message;
            }
          } else {
            state.projectSettingErrMsg = "Something went to wrong !";
          }
        })
    },
  });

  export const { removeAll, removeProjectSettingErrMsg, removeProjectSettingMsg} =
  mainSlice.actions;
export default mainSlice.reducer;
