// import "./AddUser.scss"
import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import queryString from "query-string";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { fetchProject } from "../../../../Redux/ProjectManagementSlice";
import {
  createProjectSetting,
  removeCreateProjectSettingMsg,
  removeCreateProjectSettingErrMsg,
  fetchSingleProjectSetting,
  removeUpdateProjectSettingMsg,
  removeUpdateProjectSettingErrMsg,
  updateProjectSetting
} from "../../../../Redux/AdminProjectSettingSlice";

export default function EditProjectSetting() {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    status,
    createProjectSettingMsg,
    createProjectSettingErrMsg,
    singleProjectSetting,
    updateProjectSettingMsg,
    updateProjectSettingErrMsg,
  } = useSelector((state) => state?.AdminProjectSettingSlice);

  const { projectList } = useSelector((state) => state?.project);
  let projectSetting_id =
    queryString?.parse(history?.location?.search)?.id || 0;

  const [inputBoxData, setInputBoxData] = useState({
    project_code: "",
    setting_name: "",
    setting_value: "",
  });

  const [inputErrorData, setInputErrorData] = useState({
    setting_name: "",
    project_code: "",
    setting_value: "",
  });

  const handleChange = (e) => {
    let temp = { ...inputBoxData };
    temp[e.target.name] = e.target.value;
    setInputBoxData(temp);
  };

  const handleErrorRemove = (e) => {
    let temp = { ...inputErrorData };
    temp[e.target.name] = "";
    setInputErrorData(temp);
  };

  const validate = () => {
    let isError = false;
    let temp = { ...inputErrorData };

    if (inputBoxData?.setting_name === "") {
      temp.setting_name = "Setting Name can not be empty";
      isError = true;
    }

    if (inputBoxData?.project_code === "") {
      temp.project_code = "Project Name can not be empty";
      isError = true;
    }

    if (inputBoxData?.setting_value === "") {
      temp.setting_value = "value can not be empty";
      isError = true;
    }

    setInputErrorData(temp);
    if (isError) window.scroll(0, 0);
    return isError;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var formData = new FormData();
    let isError = validate();
    if (!isError) {
      Object.entries(inputBoxData).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("id", projectSetting_id);
      dispatch(updateProjectSetting(formData));
    }
  };

  useEffect(() => {
    dispatch(fetchProject());
    dispatch(fetchSingleProjectSetting(projectSetting_id));
  }, [dispatch]);

  useEffect(() => {
    if (createProjectSettingMsg) {
      toast.success(createProjectSettingMsg);
    }
    if (createProjectSettingErrMsg) {
      toast.error(createProjectSettingErrMsg);
    }
    dispatch(removeCreateProjectSettingMsg());
    dispatch(removeCreateProjectSettingErrMsg());
  }, [createProjectSettingMsg, createProjectSettingErrMsg]);

  useEffect(() => {
    if (singleProjectSetting) {
      let temp = { ...inputBoxData };
      temp["project_code"] = singleProjectSetting?.project_code?._id;
      temp["setting_name"] = singleProjectSetting?.setting_name;
      temp["setting_value"] = singleProjectSetting?.setting_value;
      setInputBoxData(temp);
    }
  }, [singleProjectSetting]);

  useEffect(() => {
    if(updateProjectSettingMsg){
      toast.success(updateProjectSettingMsg)
    }
    if(updateProjectSettingErrMsg){
      toast.error(updateProjectSettingErrMsg)
    }
    dispatch(removeUpdateProjectSettingMsg);
    dispatch(removeUpdateProjectSettingErrMsg);
  }, [updateProjectSettingMsg,updateProjectSettingErrMsg])

  return (
    <div>
      <div className="settingOuter">
        <h2>Edit Project setting</h2>
        <hr />
        <form onSubmit={handleSubmit} autocomplete="off">
          <div className="formInner">
            <div className="form-group py-1">
              <label>Select Project</label>
              <div className="fieldWrapper">
                <select
                  name="project_code"
                  id="project_code"
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                >
                  <option value="" disabled selected>
                    Select Project
                  </option>
                  {projectList &&
                    projectList.map((object, i) => (
                      <option
                        value={object._id}
                        selected={object?._id === inputBoxData?.project_code}
                      >
                        {object.project_name}
                      </option>
                    ))}
                </select>
                {!!inputErrorData?.project_code && (
                  <div className="error_danger">
                    {inputErrorData?.project_code}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group py-1">
              <label>Project Setting name</label>
              <div className="fieldWrapper">
                <input
                  type="text"
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.setting_name}
                  name="setting_name"
                  placeholder="Enter Project Setting Name"
                />
                {!!inputErrorData?.setting_name && (
                  <div className="error_danger">
                    {inputErrorData?.setting_name}
                  </div>
                )}
              </div>
            </div>

            <div className="form-group py-1">
              <label>Project Setting Value</label>
              <div className="fieldWrapper">
                <input
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.setting_value}
                  type="text"
                  name="setting_value"
                  placeholder="Enter Project Setting Value"
                />
                {!!inputErrorData?.setting_value && (
                  <div className="error_danger">
                    {inputErrorData?.setting_value}
                  </div>
                )}
              </div>
            </div>

            <div className="btnwrap">
              <button
                type="submit"
                className="btn"
                disabled={status === "loading"}
              >
                {status === "loading" && (
                  <>
                    <i className="fas fa-spinner fa-pulse " />
                    &nbsp;
                  </>
                )}
                Submit
              </button>
              <Link to="admin-projectsetting" className="btn mx-2">
                {" "}
                <i className="fas fa-chevron-left"></i> Back
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
