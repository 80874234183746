import "./Analytics.scss";
import React from 'react'

import ShapePieChart from "../../Share_Module/chart/ShapePieChart";
import BarChartSample from "../../Share_Module/chart/BarChartSample";
import VerticalBar from "../../Share_Module/chart/VerticalBar";
import PieChart from "../../Share_Module/chart/PieChart";
import { height } from "@mui/system";
function Analytics() {
  return (
    <div>
       <div className="graphsec">
         <div className="row">
           <div className="col-md-5">
             <div className="item">
               {/* <ShapePieChart/> */}
               <PieChart/>
               {/* <img src="images/graph1.png" alt="" /> */}
             </div>
           </div>
           <div className="col-md-7">
             <div className="item graph2" style={{height:"91%"}}>
              {/* <BarChartSample/> */}
              <VerticalBar/>
               {/* <img src="images/graph2.png" alt="" /> */}
             </div>
           </div>
         </div>
       </div>
    </div>
  );
}

export default Analytics;
