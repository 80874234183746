import "./Page404.scss";
import React from 'react'
import { Link } from "react-router-dom";

export default function Page404() {
  return (
    <div>
      <div className="pageNotFound">
      <h1>404</h1>
      <h3>Opps!!</h3>
      <h5>THAT PAGE DOES NOT EXISTS OR IS UNAVAILABLE</h5>
      <Link to="/" className="btn">Go Back to Home</Link>
      </div>
    </div>
  )
}
