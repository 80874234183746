// import "./AddUser.scss"
import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import queryString from "query-string";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

// import {
//   projectSetting,
//   removeProjectSettingErrMsg,
//   removeProjectSettingMsg,
// } from "../../../Redux/SettingManagementSlice";

import { organizetionList } from "../../../../Redux/UserAuthenticationSlice";
import { fetchProject } from "../../../../Redux/ProjectManagementSlice";
import { updateTestsuite,removeUpdateTestSuiteMsg,removeUpdateTestSuiteErrMsg,singleTestsuiteFetch } from "../../../../Redux/AdminTestSuiteSlice";


export default function EditTestSuits() {
  const history = useHistory();
  const dispatch = useDispatch();

  const { projectSettingErrMsg, projectSettingMsg } = useSelector(
    (state) => state?.SettingManagementSlice
  );

  const { organizetionArray } = useSelector(
    (state) => state?.UserAuthenticationSlice
  );
  const { projectList } = useSelector((state) => state?.project);

  const { status,updateTestSuiteMsg,updateTestSuiteErrMsg,singleTestSuite } = useSelector((state) => state?.AdminTestSuiteSlice);



  let testSuite_id = queryString?.parse(history?.location?.search)?.id || 0;
  const [inputBoxData, setInputBoxData] = useState({
    project_code : "",
    org_code : "",
    suite_name :"",
  });
  const [inputErrorData, setInputErrorData] = useState({
    project_code : "",
    org_code : "",
    suite_name :"",
  });

  const handleChange = (e) => {
    let temp = { ...inputBoxData };
    temp[e.target.name] = e.target.value;
    setInputBoxData(temp);
  };

  const handleErrorRemove = (e) => {
    let temp = { ...inputErrorData };
    temp[e.target.name] = "";
    setInputErrorData(temp);
  };

  const validate = () => {
    let isError = false;
    let temp = { ...inputErrorData };
    if (inputBoxData?.org_code === "") {
      temp.org_code = "Organization name can not be empty";
      isError = true;
    }
    if (inputBoxData?.project_code === "") {
      temp.project_code = "project name can not be empty";
      isError = true;
    }
    if (inputBoxData?.suite_name === "") {
      temp.suite_name = "Suite name can not be empty";
      isError = true;
    }
    setInputErrorData(temp);
    if (isError) window.scroll(0, 0);
    return isError;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    var formData = new FormData();
    let isError = validate();
    if (!isError) {
      Object.entries(inputBoxData).forEach(([key, value]) => {
        formData.append(key, value);
      });
      // formData.append("id", testSuite_id);
      const data ={
        project_code : inputBoxData?.project_code,
        org_code : inputBoxData?.org_code,
        suite_name :inputBoxData?.suite_name,
        id:testSuite_id
      } 
      dispatch(updateTestsuite(data));
      
    }
  };
  useEffect(() => {
    dispatch(organizetionList());
    dispatch(fetchProject());
    dispatch(singleTestsuiteFetch(testSuite_id))
  }, [dispatch]);
  useEffect(() => {
   if(updateTestSuiteMsg){
    toast.success(updateTestSuiteMsg)
   }
   if(updateTestSuiteErrMsg){
    toast.error(updateTestSuiteErrMsg)
   }
   dispatch(removeUpdateTestSuiteMsg());
   dispatch(removeUpdateTestSuiteErrMsg());
  }, [updateTestSuiteMsg,updateTestSuiteErrMsg])

  // console.log(singleTestSuite)
  useEffect(() => {
   if(singleTestSuite){
     let temp = { ...inputBoxData}
     temp['project_code']=singleTestSuite?.project_code?._id
     temp['org_code']=singleTestSuite?.org_code?._id
     temp['suite_name']= singleTestSuite?.suite_name
     setInputBoxData(temp);
   }
  }, [singleTestSuite])
  




    return (
      <div>
      <div className="settingOuter">
        <h2>Edit Test Suites</h2>
        <hr/>
        <form onSubmit={handleSubmit} autocomplete="off">
          <div className="formInner">
          <div className="form-group py-1">
              <label>Select Organization</label>
              <div className="fieldWrapper">
              <select name="org_code" id="org_code"
                onChange={handleChange}
                onFocus={handleErrorRemove}
              >
                <option value="" disabled selected >-- Select Organization --</option>
                {organizetionArray &&
                    organizetionArray?.filter(gs=>gs.isDeleted === false)?.map((org, index) => (
                      <option value={org._id} key={org._id} selected={inputBoxData?.org_code == org._id}>
                        {org.org_name}
                      </option>
                ))}
              </select>
                {!!inputErrorData?.org_code && (
                  <div className="error_danger">{inputErrorData?.org_code}</div>
                )}
              </div>
            </div>


            <div className="form-group py-1">
              <label>Select Project</label>
              <div className="fieldWrapper">
              <select name="project_code" id="project_code"
                onChange={handleChange}
                onFocus={handleErrorRemove}
              >
                <option value="">-- Select Project --</option>
                {projectList &&
                    projectList?.filter(gs=>gs?.org_code == inputBoxData?.org_code)?.map((pro, index) => (
                      <option value={pro?._id} key={pro._id} selected={inputBoxData?.project_code == pro._id}>
                        {pro.project_name}
                      </option>
                    ))}
              </select>
                {!!inputErrorData?.project_code && (
                  <div className="error_danger">{inputErrorData?.project_code}</div>
                )}
              </div>
            </div>

            <div className="form-group py-1">
              <label>Test Suites</label>
              <div className="fieldWrapper">
                <input
                  type="text"
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.suite_name}
                  name="suite_name"
                  placeholder="Enter Test Suites"
                />
                {!!inputErrorData?.suite_name && (
                  <div className="error_danger">{inputErrorData?.suite_name}</div>
                )}
              </div>
            </div>
            <div className="btnwrap">
            <button type="submit" className="btn"
               disabled={status === "loading"}
              >
              {status === "loading" && (
                <>
                  <i className="fas fa-spinner fa-pulse " />
                  &nbsp;
                </>
              )}
                Submit
              </button>
              <Link to="admin-testsuite" className="btn mx-2"> <i className="fas fa-chevron-left"></i> Back</Link>

            </div>
          </div>
        </form>
      </div>
    </div>
    )
}


