import "./DataTable.scss";
import React from 'react'

export default function DataTable() {
  return (
    <div>
      <div className="addbtn">
        <button type="button" className="btn">
         Add
        </button>
      </div>
      <div className="searchFilter">
        <div className="searchBar">
          <input type="text" placeholder="Search" />
          <button type="button" className="btn">
            <img src="images/search.svg" alt="" />
          </button>
        </div>
        <div className="filtersec">
          <select name="" id="">
            <option value="">Option 1</option>
            <option value="">Option 2</option>
            <option value="">Option 3</option>
          </select>
        </div>
      </div>
      <div className="dataSection">
        <div className="table-responsive"> 
          <table>
            <thead>
              <tr>
                <th>No. <button type="button"><img src="images/sorting.svg" alt="" /></button></th>
                <th>Name <button type="button"><img src="images/sorting.svg" alt="" /></button></th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>01</td>
                <td>Dummy</td>
                <td> Lorem ipsum Dummy is a text</td>
              </tr>
              <tr>
                <td>01</td>
                <td>Dummy</td>
                <td> Lorem ipsum Dummy is a text</td>
              </tr>
              <tr>
                <td>01</td>
                <td>Dummy</td>
                <td> Lorem ipsum Dummy is a text</td>
              </tr>
            </tbody>
          </table>
        </div>
        <nav className="paginations" aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item"><a class="page-link" href="#">Previous</a></li>
          <li class="page-item"><a class="page-link" href="#">1</a></li>
          <li class="page-item"><a class="page-link" href="#">2</a></li>
          <li class="page-item"><a class="page-link" href="#">3</a></li>
          <li class="page-item"><a class="page-link" href="#">Next</a></li>
        </ul>
      </nav>
      </div>
      
    </div>
  )
}
