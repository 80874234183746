import "./Home.scss";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import {
  fetchProject,
  fetchSingleProject,
  clearProjectErrorMsg,
  clearSingleProjectErrorMsg,
  setProjectName,
} from "../../Redux/ProjectManagementSlice";
import {
  setOrg
} from "../../Redux/UserAuthenticationSlice";
import HomeSkeleton from "./HomeSkeleton";
import { imagePath } from "./../../API/index";

function Home() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    status,
    projectList,
    projectErrMsg,
    singleProjectList,
    singleProjectErrMsg,
    projectName,
  } = useSelector((state) => state?.project);
  const { userData } = useSelector((state) => state?.UserAuthenticationSlice);
  const { organization_code } = useSelector((state) => state?.UserAuthenticationSlice);
  const [orgDetails, setOrgDetails] = useState(userData);
  useEffect(() => {
    if (organization_code) {
       dispatch(fetchSingleProject(organization_code));
    }
    return () => {
      dispatch(clearProjectErrorMsg());
      dispatch(clearSingleProjectErrorMsg());
    };
  }, [dispatch, organization_code]);

  useEffect(() => {
    dispatch(setProjectName(""))
  }, [dispatch])

  const projectname =(project_name)=>{
    dispatch(setProjectName(project_name))
  }
 // console.log("lllllllllllllllllllllll",singleProjectList)
  return (
    <div>
      <div className="projectsWrapper">
        <div className="row">
          {status === "loading" ? (
            <HomeSkeleton />
          ) : singleProjectList.filter(
            (pro) => pro?.isDeleted === false && pro?.status === "Active"
          ).length === 0 ? (
            <div className="col-lg-12 col-md-12">
              <div className="projectItem">
                {/* <figure><img src="images/projectic2.svg" alt="" /></figure> */}
                <h2>Project not found!</h2>
              </div>
            </div>
          ) :  (
            singleProjectList
              .filter(
                (pro) => pro?.isDeleted === false && pro?.status === "Active"
              )
              .map((project, i) => (
                <div className="col-lg-4 col-md-6" key={project?._id}>
                  <div className="projectItem">
                    <Link to={`/project-setting?id=${project?._id}`} className="seetingpgbtn">
                      <img src="images/settingic.svg" alt="" />
                    </Link>
                    <Link to={`/test-suites?project_code=${project?._id}`} onClick={()=>projectname(project?.project_name)}>
                      <figure>
                        <img
                          src={`${imagePath}/uploads/project/${project.image}`}
                          alt=""
                        />
                      </figure>
                    </Link>
                    <Link to={`/test-suites?project_code=${project?._id}`} onClick={()=>projectname(project?.project_name)}>
                      {" "}
                      <h3>{project?.type}</h3>{" "}
                    </Link>
                    <Link to={`/test-suites?project_code=${project?._id}`} onClick={()=>projectname(project?.project_name)}>
                      <h4>{project?.project_name}</h4>
                    </Link>
                  </div>
                </div>
              ))
          )}
          {/* 
            <div className="col-lg-4 col-md-6">
              <div className="projectItem">
              <Link to="/Setting" className="seetingpgbtn"><img src="images/settingic.svg" alt="" /></Link>
                <figure><img src="images/projectic2.svg" alt="" /></figure>
                <h3>Quality</h3>
                <h4>SQA Project Name</h4>
              </div>
            </div> */}
          {/*      <div className="col-lg-4 col-md-6">
              <div className="projectItem">
              <Link to="/Setting" className="seetingpgbtn"><img src="images/settingic.svg" alt="" /></Link>
                <figure><img src="images/projectic3.svg" alt="" /></figure>
                <h3>Quality</h3>
                <h4>SQA Project Name</h4>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="projectItem">
              <Link to="/Setting" className="seetingpgbtn"><img src="images/settingic.svg" alt="" /></Link>
                <figure><img src="images/projectic4.svg" alt="" /></figure>
                <h3>Safety</h3>
                <h4>SQA Project Name</h4>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="projectItem">
              <Link to="/Setting" className="seetingpgbtn"><img src="images/settingic.svg" alt="" /></Link>
                <figure><img src="images/projectic5.svg" alt="" /></figure>
                <h3>Commercial</h3>
                <h4>SQA Project Name</h4>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="projectItem">
              <Link to="/Setting" className="seetingpgbtn"><img src="images/settingic.svg" alt="" /></Link>
                <figure><img src="images/projectic6.svg" alt="" /></figure>
                <h3>Regulatory</h3>
                <h4>SQA Project Name</h4>
              </div>
            </div>
           */}
        </div>
      </div>
    </div>
  );
}
export default Home;
