import "./Header.scss";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation, useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { useSelector, useDispatch } from "react-redux";
import {
  getStorage,
  clearLocalStorage,
  clearSessionStorage,
  ApiHelperFunction,
} from "../../helpers/apiHelper";
import { setOrg } from "../../Redux/UserAuthenticationSlice";
function Header() {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state?.UserAuthenticationSlice);
  const { organization_code } = useSelector((state) => state?.UserAuthenticationSlice);
  const { projectName } = useSelector((state) => state?.project);
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [organizetion, setOrganizetion] = useState([]);
  
  const ShowSidebar = () => {
    document.body.classList.toggle("body-active");
  };

  const handleLogout = async () => {
    clearLocalStorage("UserAuthToken");
    clearSessionStorage("UserAuthToken");
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (a) => {
    if (a === 1) {
      setAnchorEl(null);
      history.push("/profile-update");
    } else if (a === 2) {
      setAnchorEl(null);
      history.push("/change-password");
    } else if (a === 3) {
      setAnchorEl(null);
      history.push("/setting");
    } else {
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    if (userData) {
      setOrganizetion(userData?.org_code);
      // console.log("aaaaaaaaaaaa",userData?.org_code?.length>0&&userData?.org_code[0])
      dispatch(
        setOrg(userData?.org_code?._id)
      );
    }
  }, [userData]);

  const orgSelect = (e) => {
    // console.log(e.target.value);
    dispatch(setOrg(e.target.value));
  };
  // console.log(userData?.org_code?._id)
  return (
    <div>
      <div className="header">
        <div className="headerInner">
          <div className="logo">
            <Link to="/">
              <img src="images/logo.png" alt="" />
            </Link>
          </div>
          {(getStorage("UserAuthToken") && splitLocation[1] == "test-suites") ? (
            <p className="testCaseName">
             Project name : {projectName || localStorage.getItem("projectName")}
            </p>
          ) : (getStorage("UserAuthToken") && splitLocation[1] == "/") ||
            (getStorage("UserAuthToken") && splitLocation[1] == "") ? (
            <p className="testCaseName">
               Organization name : { userData?.org_code?.org_name}
            </p>
          ) : null}
          <div className="headerRight">
            {getStorage("UserAuthToken") && (
              <ul>
                <li></li>
                <li className={splitLocation[1] === "Setting" ? "active" : ""}>
                  <Tooltip title="Setting">
                    <Link 
                    // to="/setting"
                    id="basic-button"
                    aria-controls="basic-menu"
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    >
                      <img src="images/settingic.svg" alt="" />
                    </Link>
                  </Tooltip>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => handleClose(0)}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={() => handleClose(1)}>
                      Profile Update
                    </MenuItem>
                    <MenuItem onClick={() => handleClose(2)}>
                      Change Password
                    </MenuItem>
                    {/* <MenuItem onClick={() => handleClose(3)}>Settings</MenuItem> */}
                  </Menu>
                </li>
                <li className={splitLocation[1] === "Help" ? "active" : ""}>
                  <Tooltip title="Help">
                    <Link to="/Help">
                      <img src="images/questionic.svg" alt="" />
                    </Link>
                  </Tooltip>
                </li>
                <li
                  className={splitLocation[1] === "login" ? "active" : ""}
                  onClick={handleLogout}
                >
                  <Tooltip title="Logout">
                    <Link>
                      <img src="images/logoutic.svg" alt="" />
                      
                    </Link>
                  </Tooltip>
                </li>
              </ul>
            )}

            <button type="button" onClick={ShowSidebar} className="menubtn">
              <img src="images/menuic.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
