import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import {
  ApiHelperFunction,
  JenkinsApiCall
} from "../helpers/apiHelper";
export const testSuitesFetch =
  createAsyncThunk("testCase/TestSuite", async (id) => {
    const response = await ApiHelperFunction({
      urlPath: `api/test_suite/listOne/${id}`,
      method: "get",
    });
    let resData = response?.data;
    return resData;
  });
export const testCaseFetch =
  createAsyncThunk("testCase/TestCase", async () => {
    const response = await ApiHelperFunction({
      urlPath: "api/test_case/list",
      method: "get",
    });
    let resData = response?.data;
    return resData;
  });

export const startJenkinsBuild =
  createAsyncThunk("jekins/startJenkinsBuild", async () => {
    const response = await JenkinsApiCall({
      urlPath: "http://admin:111e76cad1850ef7539ff4546d7987080a@localhost:8080/job/SQAAuto/build?testcase=VerifyReviewWorkFlow&testsuite=Suite1",
      method: "post",
    });
    let resData = response;
    return resData;
  });

export const stopJenkinsBuild =
  createAsyncThunk("jekins/stopJenkinsBuild", async () => {
    const response = await JenkinsApiCall({
      urlPath: "http://admin:111e76cad1850ef7539ff4546d7987080a@localhost:8080/job/SQAAuto/18/stop",
      method: "post",
    });
    let resData = response;
    return resData;
  });

const initialState = {
  status: "idle",
  testCase: [],
  testSuite: [],
  testCaseErrMsg: "",
  testSuiteErrMsg: "",
  localstroage: [],
};

export const mainSlice = createSlice({
  name: "testcase",
  initialState,
  reducers: {
    removeAll: (state) => {
      state = initialState;
    },
    clearTestSuiteErrorMsg: (state) => {
      state.testSuiteErrMsg = "";
    },
    clearTestCasesErrorMsg: (state) => {
      state.testCaseErrMsg = "";
    },
    locstroe: (state, action) => {
      state.localstroage = action.payload;
    },
    clearlocstroe: (state, action) => {
      state.localstroage = [];
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(testSuitesFetch.pending, (state) => {
        state.status = "loading";
      })
      .addCase(testSuitesFetch.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload != undefined) {
          if (action.payload.status === 200) {
            state.testSuite = action.payload.data;
          } else {
            state.testSuiteErrMsg = action.payload.message;
          }
        } else {

          //state.projectErrMsg = "Something went to wrong !";
        }
      })
      .addCase(testCaseFetch.pending, (state) => {
        state.status = "loading";
      })
      .addCase(testCaseFetch.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload != undefined) {
          if (action.payload.status === 200) {
            state.testCase = action.payload.data;
          } else {
            state.testCaseErrMsg = action.payload.message;
          }
        } else {

          //state.projectErrMsg = "Something went to wrong !";
        }
      }).addCase(startJenkinsBuild.pending, (state) => {
        state.status = "loading";
      })
      .addCase(startJenkinsBuild.fulfilled, (state, action) => {
        state.status = "idle";
        console.log(action.payload)
      }).addCase(stopJenkinsBuild.pending, (state) => {
        state.status = "loading";
      })
      .addCase(stopJenkinsBuild.fulfilled, (state, action) => {
        state.status = "idle";
        console.log(action.payload)
      })

  },
});
export const {
  removeAll,
  clearTestSuiteErrorMsg,
  clearTestCasesErrorMsg,
  locstroe,
  clearlocstroe
} = mainSlice.actions;
export default mainSlice.reducer;
