import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export default function HomeSkeleton() {
  return (
    <>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((object, i) => (
        <div className="col-lg-4 col-md-6">
          <div className="projectItem">
            <Stack>
              <figure>
                {" "}
                <Skeleton variant="circular" width={80} height={80} />{" "}
              </figure>
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Stack>
          </div>
        </div>
      ))}
    </>
  );
}
