import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useHistory,
  Link,
} from "react-router-dom";
import Home from "./Components/Home/Home";
import ProjectSetting from "./Components/Home/Setting/Setting";
import Header from "./Share_Module/Header/Header.jsx";
import "./App.scss";
import Sidebar from "./Share_Module/Sidebar/Sidebar.jsx";
import TestSuites from "./Components/Test-Suites/Test-Suites.jsx";
import Analytics from "./Components/Analytics/Analytics.jsx";
import Setting from "./Components/Setting/Setting.jsx";
import Help from "./Components/Help/Help.jsx";
import Login from "./Components/Login/Login.jsx";
import ForgotPassword from "./Components/ForgotPassword/ForgotPassword.jsx";
import SidebarPageWrapper from "./Share_Module/SidebarPageWrapper/SidebarPageWrapper.jsx";
import Register from "./Components/Register/Register";
import PrivateRoute from "./Components/RouterWraper/PrivateRoute";
import AdminRoute from "./Components/RouterWraper/AdminRoute";
import UserRoute from "./Components/RouterWraper/UserRoute";

import { getStorage } from "./helpers/apiHelper";
import { useDispatch, useSelector } from "react-redux";
import { userDetails } from "./Redux/UserAuthenticationSlice";
import ChangePassword from "./Components/UserDetails/ChangePassword/ChangePassword";
import UpdateProfile from "./Components/UserDetails/UpdateProfile/UpdateProfile";
import { SettingsCellSharp } from "@material-ui/icons";
import Users from "./Components/Admin/Users/Users";
import DataTable from "./Components/DataTable/DataTable";
import DataEntry from "./Components/DataEntry/DataEntry";
import Page404 from "./Components/Page404/Page404";
import Footer from "./Share_Module/Footer/Footer";
import AddUser from "./Components/Admin/Users/AddUser/AddUser";
import EditUser from "./Components/Admin/Users/EditUser/EditUser";
import Organization from "./Components/Admin/Organization/Organization";
import AddOrganization from "./Components/Admin/Organization/AddOrganization/AddOrganization";
import EditOrganization from "./Components/Admin/Organization/EditOrganization/EditOrganization";
import AddProject from "./Components/Admin/Projects/AddProject/AddProject";
import Project from "./Components/Admin/Projects/Project";
import EditProject from "./Components/Admin/Projects/EditProject/EditProject";

import AdminProjectSetting from "./Components/Admin/ProjectSetting/ProjectSetting";
import AddProjectSetting from "./Components/Admin/ProjectSetting/AddProjectSetting/AddProjectSetting";
import EditProjectSetting from "./Components/Admin/ProjectSetting/EditProjctSetting/EditProjectSetting";
import TestSuits from "./Components/Admin/TestSuite/TestSuits";
import AddTestSuite from "./Components/Admin/TestSuite/AddTestSuite/AddTestSuite";
import EditTestSuits from "./Components/Admin/TestSuite/EditTestSuits/EditTestSuits";
import TestCases from "./Components/Admin/TestCases/TestCases";
import AddTestCases from "./Components/Admin/TestCases/AddTestCases/AddTestCases";
import EditTestCases from "./Components/Admin/TestCases/EditTestCases/EditTestCases";
function App(props) {
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state?.UserAuthenticationSlice);
  let token = getStorage("UserAuthToken");
  useEffect(() => {
    if (token && token !== undefined && token !== "") {
      dispatch(userDetails());
    }
  }, [dispatch, token]);

  return (
    <div className="App">
      <Router>
        <Header />
        {/* <div className="sidebarPageWrapper"> */}
        <SidebarPageWrapper>
          <Sidebar />
          <div className={`pageWrapper`}>
            <Switch>
              <Route path="/DataTable">
                <DataTable />
              </Route>
              <Route path="/DataEntry">
                <DataEntry />
              </Route>
              <Route path="/Page404">
                <Page404 />
              </Route>
              <UserRoute component={Home} path="/" exact />
              <UserRoute component={TestSuites} path="/test-suites" />

              <UserRoute component={ProjectSetting} path="/project-setting" />
              <AdminRoute component={Users} path="/admin-users" />
              <AdminRoute component={AddUser} path="/admin-add-users" />
              <AdminRoute component={EditUser} path="/admin-edit-users" />
              <AdminRoute
                component={Organization}
                path="/admin-organizations"
              />
              <AdminRoute
                component={AddOrganization}
                path="/admin-add-organizations"
              />
              <AdminRoute
                component={EditOrganization}
                path="/admin-edit-organization"
              />

              <AdminRoute component={AddProject} path="/admin-add-project" />
              <AdminRoute component={Project} path="/admin-project" />
              <AdminRoute component={EditProject} path="/admin-edit-project" />
              <AdminRoute component={Analytics} path="/analytics" />

              <AdminRoute
                component={AddProjectSetting}
                path="/admin-add-projectsetting"
              />
              <AdminRoute
                component={AdminProjectSetting}
                path="/admin-projectsetting"
              />
              <AdminRoute
                component={EditProjectSetting}
                path="/admin-edit-projectsetting"
              />

              <AdminRoute component={TestCases} path="/admin-testcases" />
              <AdminRoute
                component={AddTestCases}
                path="/admin-add-testcases"
              />
              <AdminRoute
                component={EditTestCases}
                path="/admin-edit-testcases"
              />

              <AdminRoute
                component={AddTestSuite}
                path="/admin-add-testsuite"
              />
              <AdminRoute component={TestSuits} path="/admin-testsuite" />
              <AdminRoute
                component={EditTestSuits}
                path="/admin-edit-testsuite"
              />

              <PrivateRoute component={Help} path="/Help" />
              <PrivateRoute component={Setting} path="/setting" />
              <PrivateRoute
                component={ChangePassword}
                path="/change-password"
              />
              <PrivateRoute component={UpdateProfile} path="/profile-update" />

              <Route
                exact
                path="/login"
                render={(props) =>
                  !getStorage("UserAuthToken") ? (
                    <Login {...props} />
                  ) : (
                    <Redirect to={"/"} />
                  )
                }
              />
              <Route
                exact
                path="/forgot-password"
                render={(props) =>
                  !getStorage("UserAuthToken") ? (
                    <ForgotPassword {...props} />
                  ) : (
                    <Redirect to={"/"} />
                  )
                }
              />
              <Route
                exact
                path="/register11111"
                render={(props) =>
                  !getStorage("UserAuthToken") ? (
                    <Register {...props} />
                  ) : (
                    <Redirect to={"/"} />
                  )
                }
              />
              <PrivateRoute />
            </Switch>
          </div>
        </SidebarPageWrapper>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
