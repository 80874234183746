import "./UpdateProfile.scss";
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
UpdateProfileDetails,
removeupdateProfileMsg,
removeupdateProfileErrorMsg,
} from "../../../Redux/UserAuthenticationSlice";
import { toast } from "react-toastify";

import { setLocaleStorage } from "../../../helpers/apiHelper";
function UpdateProfile() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {status,updateProfileErrMsg, updateProfileMsg,userData} = useSelector(
    (state) => state?.UserAuthenticationSlice
  );

  const [inputBoxData, setInputBoxData] = useState({
    first_name: "",
    last_name: "",
    // phone:"",
    email:""
  });
  const [inputErrorData, setInputErrorData] = useState({
    first_name: "",
    last_name: "",
    // phone:"",
    email:""
  });

  const handleChange = (e) => {
    let temp = { ...inputBoxData };
    temp[e.target.name] = e.target.value;
    setInputBoxData(temp);
  };
  const handleErrorRemove = (e) => {
    let temp = { ...inputErrorData };
    temp[e.target.name] = "";
    setInputErrorData(temp);
  };

  useEffect(() => {
    if (updateProfileMsg != "") {
      toast.success(updateProfileMsg);
    }
    if (updateProfileErrMsg != "") {
      toast.success(updateProfileErrMsg);
    }
    return () => {
      dispatch(removeupdateProfileMsg());
      dispatch(removeupdateProfileErrorMsg());
    };
  }, [updateProfileMsg, updateProfileErrMsg]);

  useEffect(() => {
    let inputData={...inputBoxData}
    inputData.first_name=userData?.first_name;
    inputData.last_name=userData?.last_name;
    inputData.email=userData?.email;
    // inputData.phone=userData?.phone;
    setInputBoxData(inputData);
  }, [userData])


  const validate = () => {
    const emailReg =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneReg = /^\d{10}$/;

    let isError = false;
    let temp = { ...inputErrorData };
    if (inputBoxData?.first_name === "") {
      temp.first_name = "First name can not be empty";
      isError = true;
    }
    if (inputBoxData?.last_name === "") {
      temp.last_name = "Last name can not be empty";
      isError = true;
    }
    // if (inputBoxData?.phone === "") {
    //     temp.phone = "Phone can not be empty";
    //     isError = true;
    // }else if(!phoneReg.test(inputBoxData?.phone)){
    //   temp.phone = "Phone number must be a valid phone number.";
    //   isError = true;
    // }
    if (inputBoxData?.email === "") {
        temp.email = "Email Id can not be empty";
        isError = true;
    }
    else if (!emailReg.test(inputBoxData?.email)) {
      temp.email = "Email must be a valid email address.";
      isError = true;
    }
        setInputErrorData(temp);
        if (isError) window.scroll(0, 0);
        return isError;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var formData = new FormData();
    let isError = validate();
    if (!isError) {
      Object.entries(inputBoxData).forEach(([key, value]) => {
        formData.append(key, value);
      });
      dispatch(UpdateProfileDetails(formData));
     
    }
  };

  return (
    <div>
      <div className="updateUser">
        <div className="heading">
          <h2>Update Profile</h2>
        </div>
        <form onSubmit={handleSubmit} autocomplete="off">
          <div className="loginInner">
            <div className="form-group py-1">
              <label>First Name</label>
              <div className="fieldWrapper">
                <input
                  type="text"
                  name="first_name"
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.first_name}
                  placeholder="Enter First Name"
                />
                {!!inputErrorData?.first_name && (
                  <div className="error_danger">
                    {inputErrorData?.first_name}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group py-1">
              <label>Last Name</label>
              <div className="fieldWrapper">
                <input
                  type="text"
                  name="last_name"
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.last_name}
                  placeholder="Enter Last Name"
                />
                {!!inputErrorData?.last_name && (
                  <div className="error_danger">
                    {inputErrorData?.last_name}
                  </div>
                )}
              </div>
            </div>

            <div className="form-group py-1">
              <label>Email Id</label>
              <div className="fieldWrapper">
                <input
                  type="text"
                  name="email"
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.email}
                  placeholder="Enter Email ID"
                />
                {!!inputErrorData?.email && (
                  <div className="error_danger">{inputErrorData?.email}</div>
                )}
              </div>
            </div>

            {/* <div className="form-group py-1">
              <label>Phone Number</label>
              <div className="fieldWrapper">
                <input
                  type="text"
                  name="phone"
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.phone}
                  placeholder="Enter Phone Number"
                />
                {!!inputErrorData?.phone && (
                  <div className="error_danger">{inputErrorData?.phone}</div>
                )}
              </div>
            </div> */}

            <div className="Submitbtn">
              <button type="submit" className="btn"
               disabled={status === "loading"}
              >
              {status === "loading" && (
                <>
                  <i className="fas fa-spinner fa-pulse " />
                  &nbsp;
                </>
              )}
                Update
              </button>
            </div>
            
            {/* <div className="copyrighttxt">
              <p>Copyright. Spotline Inc.</p>
            </div> */}

          </div>
        </form>
      </div>
    </div>
  );
}
export default UpdateProfile;
