
// import "./AddUser.scss"
import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import queryString from "query-string";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { imagePath } from "./../../../../API/index";

import {
  singleproject,
  removeUpdateProjectMsg,
  removeUpdateProjectErrMsg,
  updateProject
} from "../../../../Redux/AdminProjectSlice";
import { organizetionList } from "../../../../Redux/UserAuthenticationSlice";
export default function EditProject() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {status,projectUpdateMsg,projectUpdateErrMsg,singleprojectlist} = useSelector(
    (state) => state?.AdminProjectSlice
  );
  const { organizetionArray } = useSelector(
    (state) => state?.UserAuthenticationSlice
  );
  let project_id = queryString?.parse(history?.location?.search)?.id || 0;
  const [imagefile,setImagefile] = useState("")
  const [image,setImage] = useState("")

  const [inputBoxData, setInputBoxData] = useState({
    project_name: "",
    org_code: "",
    type:"",
  });
  const [inputErrorData, setInputErrorData] = useState({
    project_name: "",
    org_code: "",
    image:"",
    type:"",
  });
  const handleChange = (e) => {
    let temp = { ...inputBoxData };
    temp[e.target.name] = e.target.value;
    setInputBoxData(temp);
  };
  const handleErrorRemove = (e) => {
    let temp = { ...inputErrorData };
    temp[e.target.name] = "";
    setInputErrorData(temp);
  };

  const validate = () => {
    let isError = false;
    let temp = { ...inputErrorData };

    if (inputBoxData?.project_name === "") {
      temp.project_name = "Project Name can not be empty";
      isError = true;
    }

    if (inputBoxData?.org_code === "") {
      temp.org_code = "Organization can not be empty";
      isError = true;
    }

    if (inputBoxData?.type === "") {
      temp.type = "Type can not be empty";
      isError = true;
    }
    
    // if (imagefile === "") {
    //     temp.image = " image can not be empty";
    //     isError = true;
    // }

    if (imagefile) {
      if (!imagefile.name.match(/.(jpg|jpeg|png|gif|JPG|JPEG|PNG)$/i)) {
        temp.image = "Sorry only Image format is supported";
        isError = true;
      }
    }
    setInputErrorData(temp);
    if (isError) window.scroll(0, 0);
    return isError;
  };

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      setImagefile(file);
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          setImage(reader.result);
        }
      };
      reader.readAsDataURL(e.target.files[0]);
    }
    handleErrorRemove(e);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    var formData = new FormData();
    let isError = validate();
    if (!isError) {
      Object.entries(inputBoxData).forEach(([key, value]) => {
        formData.append(key, value);
      });
      
      formData.append("image", imagefile);
      formData.append("id", project_id);
      dispatch(updateProject(formData));

    }
  };
  useEffect(() => {
    if(projectUpdateMsg){
      toast.success(projectUpdateMsg)
      dispatch(singleproject(project_id));
    }

    if(projectUpdateErrMsg){
      toast.error(projectUpdateErrMsg)
    }

    dispatch(removeUpdateProjectMsg())
    dispatch(removeUpdateProjectErrMsg())

  }, [projectUpdateMsg,projectUpdateErrMsg])

  useEffect(() => {
    dispatch(organizetionList());
    dispatch(singleproject(project_id));
  }, [dispatch]);

  useEffect(() => {
    if(singleprojectlist){
          let temp = { ...inputBoxData };
          temp["project_name"] = singleprojectlist?.project_name;
          temp["type"] = singleprojectlist?.type ;
          temp["org_code"] = singleprojectlist?.org_code?._id;
      setInputBoxData(temp);

    }
  }, [singleprojectlist])
  // console.log(inputBoxData)
    return (
      <div>
      <div className="settingOuter">
        <h2>add Project</h2>
        <hr/>
        <form onSubmit={handleSubmit} autocomplete="off">
          <div className="formInner">
            <div className="form-group py-1">
              <label>Project Name</label>
              <div className="fieldWrapper">
                <input
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.project_name}
                  type="text"
                  name="project_name"
                  placeholder="Enter Project name"
                />
                {!!inputErrorData?.project_name && (
                  <div className="error_danger">
                    {inputErrorData?.project_name}
                  </div>
                )}
              </div>
            </div>

            <div className="form-group py-1">
              <label>Project Type</label>
              <div className="fieldWrapper">
                <input
                  type="text"
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.type}
                  name="type"
                  placeholder="Enter Project Type"
                />
                {!!inputErrorData?.type && (
                  <div className="error_danger">{inputErrorData?.type}</div>
                )}
              </div>
            </div>

            <div className="form-group py-1">
              <label>Select Organization</label>
              <div className="fieldWrapper">
                <select
                  name="org_code"
                  id="org_code"
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                >
                  <option value="" disabled selected>
                    {" "}
                    Select Organization{" "} 
                  </option>
                  {organizetionArray &&
                    organizetionArray?.map((org, index) => (
                      <option value={org._id} key={org._id} selected={org?._id == inputBoxData?.org_code}>
                        {org.org_name}
                      </option>
                    ))}
                </select>
                {!!inputErrorData?.org_code && (
                  <div className="error_danger">{inputErrorData?.org_code}</div>
                )}
              </div>
            </div>

            <div className="form-group py-1">
              <label>Image</label>
              <div className="fieldWrapper">
              <img
                          src={`${imagePath}/uploads/project/${singleprojectlist?.image}`}
                          alt=""
                          width="40px"
                        />
                <input
                  type="file"
                  onChange={handleImageUpload}
                  onFocus={handleErrorRemove}
                  name="image"
                />

                {!!inputErrorData?.image && (
                  <div className="error_danger">{inputErrorData?.image}</div>
                )}
              </div>
            </div>
            <div className="btnwrap">
            <button type="submit" className="btn"
               disabled={status === "loading"}
              >
              {status === "loading" && (
                <>
                  <i className="fas fa-spinner fa-pulse " />
                  &nbsp;
                </>
              )}
                Submit
              </button>
                 <Link to="admin-project" className="btn mx-2"> <i className="fas fa-chevron-left"></i> Back</Link>
            </div>
          </div>
        </form>
      </div>
    </div>
    )
}




