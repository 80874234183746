import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getStorage } from "../../helpers/apiHelper";
import { useDispatch, useSelector } from "react-redux";

export default function UserRoute({ component: Component, ...rest }) {
    const is_Admin = JSON.parse(getStorage("userData"))?.is_admin;
    const { userData } = useSelector((state) => state?.UserAuthenticationSlice);

    return (
        <div>
              <Route
                render={(props) =>
                    getStorage("UserAuthToken") ?  (
                       is_Admin === false || userData?.is_admin === false ?
                       <Component {...props} />
                       :
                       <Redirect
                         to={{ pathname: "/admin-organizations", state: { from: props.location } }}
                       />
                     ) : (
                       <Redirect
                         to={{ pathname: "/login", state: { from: props.location } }}
                       />
                     )
                   }
      />
        </div>
    )
}
