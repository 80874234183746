import "./Project.scss";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import {
  projectListing,
  removeDeleteProjectMsg,
  removeDeleteProjectErrMsg,
  deleteProject,
  updateProject,
  removeUpdateProjectMsg,
  removeUpdateProjectErrMsg,
} from "../../../Redux/AdminProjectSlice";
import { toast } from "react-toastify";
import SweetAlertComponent from "../../../Share_Module/SweetAlertComponent/SweetAlertComponent";
import ReactPaginate from "react-paginate";
import { imagePath } from "./../../../API/index";
import Skeleton from "@mui/material/Skeleton";
function Project() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isDelete, setIsdelete] = useState(false);
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [currantP, setCurrantpage] = useState(1);
  const [query, setQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sort_field, setSortField] = useState("project_name");
  const {
    status,
    projectList,
    userErrorMsg,
    totalPage,
    currantPage,
    projectDeleteErrMsg,
    projectDeleteMsg,
    projectUpdateMsg,
    projectUpdateErrMsg,
  } = useSelector((state) => state?.AdminProjectSlice);
  useEffect(() => {
    if (totalPage) {
      setPageCount(totalPage);
    }
    if (currantPage) {
      setCurrantpage(currantPage);
    }
  }, [totalPage, currantPage]);

  const deleteProject1 = (name, id) => {
    // console.log(name)
    setUserName(name);
    setUserId(id);
    setIsdelete(true);
  };

  const handleCloneConfirm = () => {
    if (userId !== "") {
      dispatch(
        deleteProject({
          id: userId,
          page: currantP,
          sort_field: sort_field,
          sort_order: sortOrder,
        })
      );
    }
    setUserName("");
    setUserId("");
    setIsdelete(false);
  };

  useEffect(() => {
    if (projectDeleteMsg !== "") {
      toast.success(projectDeleteMsg);
      dispatch(removeDeleteProjectMsg());
    }
  }, [dispatch, projectDeleteMsg]);

  const handlePageClick = ({ selected }) => {
    setCurrantpage(selected + 1);
    dispatch(
      projectListing({
        page: selected + 1,
        query: query,
        sort_field: sort_field,
        sort_order: sortOrder,
      })
    );
  };

  const handleSearch = () => {
    // setCurrantpage(1)
    // dispatch(projectListing({page:1,query:query}));
    if (query == "") {
      setCurrantpage(currantPage);
      dispatch(
        projectListing({
          page: currantPage,
          query: query,
          sort_field: sort_field,
          sort_order: sortOrder,
        })
      );
    } else {
      setCurrantpage(1);
      dispatch(
        projectListing({
          page: 1,
          query: query,
          sort_field: sort_field,
          sort_order: sortOrder,
        })
      );
    }
  };
  useEffect(() => {
    dispatch(
      projectListing({
        page: 1,
        query: query,
        sort_field: sort_field,
        sort_order: sortOrder,
      })
    );
  }, [dispatch]);

  const short = (sort_field1) => {
    setSortField(sort_field1);
    if (sortOrder === "asc") {
      setSortOrder("desc");
      dispatch(
        projectListing({
          page: 1,
          query: query,
          sort_field: sort_field1,
          sort_order: "desc",
        })
      );
    } else {
      setSortOrder("asc");
      dispatch(
        projectListing({
          page: 1,
          query: query,
          sort_field: sort_field1,
          sort_order: "asc",
        })
      );
    }
  };

  const statuschange = (id, status) => {
    var formData = new FormData();
    formData.append("id", id);
    formData.append("status", status);
    formData.append("page", currantPage);
    formData.append("sort_field", sort_field);
    formData.append("sort_order", sortOrder);
    dispatch(updateProject(formData));
  };

  useEffect(() => {
    if (projectUpdateMsg) {
      toast.success("Status Change Successfully");
    }
    if (projectUpdateErrMsg) {
      toast.error("Status Did Not change!");
    }
    dispatch(removeUpdateProjectMsg());
    dispatch(removeUpdateProjectErrMsg());
  }, [projectUpdateMsg, projectUpdateErrMsg]);

  console.log(projectList);

  return (
    <div>
      <div className="addbtn">
        <Link to="admin-add-project" className="btn">
          Add Project
        </Link>
      </div>
      <div className="searchFilter">
        <div className="searchBar">
          <input
            type="text"
            placeholder="Search"
            value={query}
            onChange={(e) => {
              setQuery(e?.target?.value);
            }}
            onKeyPress={(event) => event.key === "Enter" && handleSearch()}
          />
          <button
            type="button"
            className="btn"
            onClick={(event) => handleSearch()}
          >
            <img src="images/search.svg" alt="" />
          </button>
        </div>

        {/* <div className="filtersec">
            <select name="" id="">
              <option value="">Option 1</option>
              <option value="">Option 2</option>
              <option value="">Option 3</option>
            </select>
          </div> */}
      </div>
      <div className="dataSection">
        <div className="table-responsive">
          <table>
            <thead>
              <tr>
                <th>
                  Project Name{" "}
                  <button type="button" onClick={() => short("project_name")}>
                    <img src="images/sorting.svg" alt="" />
                  </button>
                </th>
                <th>
                  Project Type{" "}
                  <button type="button" onClick={() => short("type")}>
                    <img src="images/sorting.svg" alt="" />
                  </button>
                </th>
                <th>Organization Name </th>
                <th>Project Image</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {status === "loading" ? (
                [1, 1, 1, 1, 1, 1]?.map((obj, i) => (
                  <tr className="" key={i}>
                    <td>
                      <Skeleton variant="text" width={"99%"} height={30} />
                    </td>
                    <td>
                      <Skeleton variant="text" width={"99%"} height={30} />
                    </td>
                    <td>
                      <Skeleton variant="text" width={"99%"} height={30} />
                    </td>
                    <td>
                      <Skeleton variant="text" width={"99%"} height={30} />
                    </td>
                    <td>
                      <Skeleton variant="text" width={"99%"} height={30} />
                    </td>
                  </tr>
                ))
              ) : projectList?.length === 0 ? (
                <td colSpan="3">User not found!</td>
              ) : (
                projectList
                  ?.filter((gs) => gs.isDeleted === false)
                  ?.map((projectlist, key) => (
                    <tr key={projectlist?._id}>
                      <td>
                        {projectlist?.project_name} &nbsp;
                        {projectlist?.status == "Active" ? (
                          <span className="badge bg-success ">Active</span>
                        ) : (
                          <span className="badge bg-danger ">Inactive</span>
                        )}
                      </td>
                      <td>{projectlist?.type}</td>

                      <td>{projectlist?.org_data?.org_name}</td>

                      <td>
                        {" "}
                        <img
                          src={`${imagePath}/uploads/project/${projectlist?.image}`}
                          alt=""
                          width="40px"
                        />
                      </td>

                      <td>
                        {" "}
                        <Link to={`admin-edit-project?id=${projectlist?._id}`}>
                          <i
                            className="fas fa-edit cursor-pointer"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Edit Project"
                          ></i>
                        </Link>{" "}
                        &nbsp;&nbsp;
                        <i
                          className="fas fa-trash-alt cursor-pointer"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Delete Project"
                          onClick={() => {
                            deleteProject1(
                              projectlist?.project_name,
                              projectlist?._id
                            );
                          }}
                        ></i>
                        {projectlist?.status == "Active" ? (
                          <>
                            &nbsp;&nbsp;{" "}
                            <i
                              className="fas fa-toggle-off cursor-pointer"
                              style={{ fontSize: "15px" }}
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Inactive"
                              onClick={() => {
                                statuschange(projectlist?._id, "Inactive");
                              }}
                            ></i>
                          </>
                        ) : (
                          <>
                            &nbsp;&nbsp;{" "}
                            <i
                              className="fas fa-toggle-on cursor-pointer"
                              style={{ fontSize: "15px" }}
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Active"
                              onClick={() => {
                                statuschange(projectlist?._id, "Active");
                              }}
                            ></i>
                          </>
                        )}
                      </td>
                    </tr>
                  ))
              )}
            </tbody>
          </table>
        </div>
        <nav className="paginations" aria-label="Page navigation example">
          <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            pageCount={pageCount}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
            forcePage={currantPage - 1}
          />
        </nav>
      </div>
      {isDelete && (
        <SweetAlertComponent
          confirm={handleCloneConfirm}
          cancle={() => setIsdelete(false)}
          title={"Are you sure?"}
          subtitle={`you want to Delete the Organization ${userName}`}
          type="danger"
        />
      )}
    </div>
  );
}
export default Project;
