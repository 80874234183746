import "./Login.scss";
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  signIn,
  removeLoginMsg,
  removeLoginErrorMsg,
} from "../../Redux/UserAuthenticationSlice";
import { setLocaleStorage } from "../../helpers/apiHelper";
import { toast } from "react-toastify";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

function Login() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loginMsg, loginErrorMsg, UserData, UserAuthToken } = useSelector(
    (state) => state?.UserAuthenticationSlice
  );
  const [inputBoxData, setInputBoxData] = useState({
    email: "",
    password: "",
    remember_me: false,
  });
  const [inputErrorData, setInputErrorData] = useState({
    email: "",
    password: "",
  });

  const [showPass, setShowPass] = useState({
    password: false,
    confirm_password: false,
  });
  const handlePassVisibility = (name) => {
    const temp = { ...showPass };
    temp[name] = !temp[name];
    setShowPass(temp);
  };

  useEffect(() => {
    if (loginMsg != "") {
      toast.success(loginMsg);
      history.push("/");
    }
    if (loginErrorMsg != "") {
      toast.error(loginErrorMsg);
    }
    return () => {
      dispatch(removeLoginMsg());
      dispatch(removeLoginErrorMsg());
    };
  }, [loginMsg, loginErrorMsg]);

  const handleChange = (e) => {
    let temp = { ...inputBoxData };
    if (e.target.name === "remember_me") {
      if (e.target.checked) {
        setLocaleStorage("remember_me", "true");
      } else {
        setLocaleStorage("remember_me", null);
      }
      temp[e.target.name] = e.target.checked;
    } else temp[e.target.name] = e.target.value;
    setInputBoxData(temp);
  };
  const handleErrorRemove = (e) => {
    let temp = { ...inputErrorData };
    temp[e.target.name] = "";
    setInputErrorData(temp);
  };
  const validate = () => {
    let isError = false;
    let temp = { ...inputErrorData };
    if (inputBoxData?.email === "") {
      temp.email = "Email Id can not be empty";
      isError = true;
    }
    if (inputBoxData?.password === "") {
      temp.password = "Password can not be empty";
      isError = true;
    }
    setInputErrorData(temp);
    if (isError) window.scroll(0, 0);
    return isError;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    var formData = new FormData();
    let isError = validate();
    if (!isError) {
      Object.entries(inputBoxData).forEach(([key, value]) => {
        formData.append(key, value);
      });
      dispatch(signIn(formData));
      setInputBoxData({
        email: "",
        password: "",
        remember_me: false,
      });
      setInputErrorData({
        email: "",
        password: "",
      });
    }
  };

  return (
    <div>
      <div className="loginpg">
        <div className="heading">
          <h2>Login</h2>
        </div>
        <form onSubmit={handleSubmit} autocomplete="off">
          <div className="loginInner">
            <div className="form-group py-1">
              <label>Email ID</label>
              <div className="fieldWrapper">
                <input
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.email}
                  placeholder="Enter your Email id"
                />
                {!!inputErrorData?.email && (
                  <div className="error_danger">{inputErrorData?.email}</div>
                )}
              </div>
            </div>
            <div className="form-group py-1 textPassword">
              <label>Password</label>
              <div className="fieldWrapper">
                <input
                  type={showPass?.password ? "text" : "password"}
                  name="password"
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.password}
                  placeholder="Enter Your Password"
                />

                <span
                  className="px-1 cp iconPassword"
                  onClick={() => handlePassVisibility("password")}
                >
                  {showPass?.password ? <Visibility /> : <VisibilityOff />}
                </span>

                {!!inputErrorData?.password && (
                  <div className="error_danger">{inputErrorData?.password}</div>
                )}
              </div>
            </div>
            <div className="form-group">
              <label>&nbsp;</label>
              <div className="fieldWrapper">
                <div className="rememberLoginbtn">
                  <label class="checkboxcontainer">
                    Remember
                    <input
                      type="checkbox"
                      name="remember_me"
                      checked={inputBoxData?.remember_me}
                      onChange={handleChange}
                      name="remember_me"
                    />
                    <span className="checkmark"></span>
                  </label>

                  <div className="btnForgotPass">
                    <button type="submit" className="btn shadow-none">
                      Login
                    </button>
                    <p>
                      <Link to="/forgot-password">Forgot Password?</Link>
                    </p>
                    {/* <p>
                      <Link to="/register">Create an account</Link>
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="copyrighttxt">
              <p>Copyright. Spotline Inc.</p>
            </div> */}
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
