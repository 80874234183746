import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getStorage } from "../../helpers/apiHelper";
import { useDispatch, useSelector } from "react-redux";
function AdminRoute({ component: Component, ...rest }) {
  const is_Admin = JSON.parse(getStorage("userData"))?.is_admin;
  const { userData } = useSelector((state) => state?.UserAuthenticationSlice);
  
  return (
    <>
      <Route
        render={(props) =>
         getStorage("UserAuthToken") ?  (
            is_Admin || userData?.is_admin ?
            <Component {...props} />
            :
            <Redirect
              to={{ pathname: "/", state: { from: props.location } }}
            />
          ) : (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }

      />
    </>
  );
}
export default AdminRoute;
