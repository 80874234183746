import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import {
  ApiHelperFunction,
  setLocaleStorage,
  setSessionStorage,
} from "../helpers/apiHelper";
import { toast } from "react-toastify";

export const signup = createAsyncThunk("user/signup", async (formData) => {
  const response = await ApiHelperFunction({
    urlPath: "api/user/signup",
    method: "post",
    formData: formData,
  });
  let resData = response?.data;
  return resData;
});

export const signIn = createAsyncThunk("user/signIn", async (formData) => {
  const response = await ApiHelperFunction({
    urlPath: "api/user/login",
    method: "post",
    formData: formData,
  });
  let resData = response?.data;
  return resData;
});

export const userDetails = createAsyncThunk(
  "user/details",
  async (formData) => {
    const response = await ApiHelperFunction({
      urlPath: "api/user/profile/get",
      method: "get",
      formData: formData,
    });
    let resData = response?.data;
    return resData;
  }
);

export const organizetionList = createAsyncThunk(
  "user/organizetion",
  async () => {
    const response = await ApiHelperFunction({
      urlPath: "api/organisation/list",
      method: "get",
      // formData: formData,
    });
    let resData = response?.data;
    return resData;
  }
);

export const changePassword = createAsyncThunk(
  "user/changepassword",
  async (formData) => {
    const response = await ApiHelperFunction({
      urlPath: "api/user/change-password",
      method: "post",
      formData: formData,
    });
    let resData = response?.data;
    return resData;
  }
);
export const UpdateProfileDetails = createAsyncThunk(
  "user/UpdateProfile",
  async (formData) => {
    const response = await ApiHelperFunction({
      urlPath: "api/user/profile/update",
      method: "post",
      formData: formData,
    });
    let resData = response?.data;
    return resData;
  }
);

export const forgotPassword = createAsyncThunk(
  "user/forgotPassword",
  async (formData) => {
    const response = await ApiHelperFunction({
      urlPath: "api/user/forgotpassword",
      method: "post",
      formData: formData,
    });
    let resData = response?.data;
    return resData;
  }
);



const initialState = {
  status: "idle",
  registerMsg: "",
  registerErrorMsg: "",
  userData: [],
  UserAuthToken: "",
  loginMsg: "",
  loginErrorMsg: "",
  userDataErrMsg: "",
  changePasswordMsg:"",
  changePasswordErrMsg:"",
  updateProfileMsg:"",
  updateProfileErrMsg:"",
  organizetionArray:[],
  organizetionErrorMsg:"",
  organization_code:"",
  forgotpasswordMsg:"",
  forgotpasswordErrMsg:"",

};
export const mainSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    removeAll: (state) => {
      state = initialState;
    },
    clearRegisterMsg: (state) => {
      state.registerMsg = "";
    },
    clearRegisterErrorMsg: (state) => {
      state.registerErrorMsg = "";
    },
    removeLoginMsg: (state) => {
      state.loginMsg = "";
    },
    removeLoginErrorMsg: (state) => {
      state.loginErrorMsg = "";
    },
    removechangePasswordMsg: (state) => {
      state.changePasswordMsg = "";
    },
    removeremovePasswordErrorMsg: (state) => {
      state.changePasswordErrMsg = "";
    },
    removeupdateProfileMsg: (state) => {
      state.updateProfileMsg = "";
    },
    removeupdateProfileErrorMsg: (state) => {
      state.updateProfileErrMsg = "";
    },
    removeOrganizetionErrorMsg: (state) => {
      state.organizetionErrorMsg = "";
    },
    removeforgotpasswordErrorMsg: (state) => {
      state.forgotpasswordErrMsg = "";
    },
    removeforgotpasswordMsg: (state) => {
      state.forgotpasswordMsg = "";
    },
    setOrg: (state,action) => {
      state.organization_code = action.payload;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(signup.pending, (state) => {
        state.status = "loading";
      })
      .addCase(signup.fulfilled, (state, action) => {
        state.status = "idle";
        // console.log(action.payload)
        if (action.payload != undefined) {
          if (action.payload.status === 200) {
            state.registerMsg = action.payload.message;
          } else {
            state.registerErrorMsg = action.payload.message;
          }
        } else {
          state.registerErrorMsg = "Something went to wrong !";
        }
      })
      .addCase(signIn.pending, (state) => {
        state.status = "loading";
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload != undefined) {
          if (action.payload.status === 200) {
            state.loginMsg = action.payload.message;
            // state.userData = action.payload.data;
            state.UserAuthToken = action.payload.token;
            if (localStorage.getItem("remember_me") === "true") {
              setLocaleStorage("UserAuthToken", action.payload.token);
              setLocaleStorage("userData", JSON.stringify(action.payload.data));
              toast.success(action.payload.message);
            } else {
              setSessionStorage("UserAuthToken", action.payload.token);
              setSessionStorage(
                "userData",
                JSON.stringify(action.payload.data)
              );
            }
          } else {
            state.loginErrorMsg = "Email Id/Password Not Match!";
          }
        } else {
          state.loginErrorMsg = "Something went to wrong !";
        }
      })
      .addCase(userDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(userDetails.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload != undefined) {
          if (action.payload.status === 200) {
            state.userData = action.payload.data;
          } else {
            state.registerErrorMsg = "Something went to wrong !";
          }
        } else {
          state.registerErrorMsg = "Something went to wrong !";
        }
      })
      .addCase(changePassword.pending, (state) => {
        state.status = "loading";
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload != undefined) {
          if (action.payload.status === 200) {
             state.changePasswordMsg = action.payload.message;
          } else {
             state.changePasswordErrMsg = action.payload.message;
          }
        } else {
          state.changePasswordErrMsg = "Something went to wrong!";
        }
      })
      .addCase(UpdateProfileDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(UpdateProfileDetails.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload != undefined) {
          if (action.payload.status === 200) {
             state.updateProfileMsg = action.payload.message;
          } else {
             state.updateProfileErrMsg = action.payload.message;
          }
        } else {
          state.updateProfileErrMsg = "Something went to wrong!";
        }
      })
      .addCase(organizetionList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(organizetionList.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload != undefined) {
          if (action.payload.status === 200) {
             state.organizetionArray = action.payload.data;
          } else {
             state.organizetionErrorMsg = action.payload.message;
          }
        } else {
          state.organizetionErrorMsg = "Something went to wrong!";
        }
      })

      .addCase(forgotPassword.pending, (state) => {
        state.status = "loading";
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload != undefined) {
          if (action.payload.status === 200) {
             state.forgotpasswordMsg = action.payload.message;
          } else {
             state.forgotpasswordErrMsg = action.payload.message;
          }
        } else {
          state.forgotpasswordErrMsg = "Something went to wrong!";
        }
      })
  },
});
export const {
  removeAll,
  clearRegisterMsg,
  clearRegisterErrorMsg,
  removeLoginMsg,
  removeLoginErrorMsg,
  removechangePasswordMsg,
  removeremovePasswordErrorMsg,
  removeupdateProfileMsg,
  removeupdateProfileErrorMsg,
  removeOrganizetionErrorMsg,
  setOrg,
  removeforgotpasswordErrorMsg,
  removeforgotpasswordMsg
} = mainSlice.actions;
export default mainSlice.reducer;