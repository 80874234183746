import "./DataEntry.scss";
import React from 'react'

export default function DataEntry() {
  return (
    <div>
        <div className="dataEntry">
          <div className="form-group">
            <input type="text" placeholder="Name" />
          </div>
          <div className="form-group">
            <input type="text" placeholder="Email" />
          </div>
          <div className="form-group">
            <input type="text" placeholder="Phone" />
          </div>
          <div className="form-group">
           <select name="" id="">
             <option value="">Option 1</option>
             <option value="">Option 2</option>
             <option value="">Option 4</option>
           </select>
          </div>
          <div className="form-group">
           <textarea placeholder="Description"></textarea>
          </div>
          
          <div className="submitbtn">
            <button type="button" className="btn">Submit</button>
          </div>
        </div>
    </div>
  )
}
