import { configureStore } from "@reduxjs/toolkit";
import UserAuthenticationSlice from "./UserAuthenticationSlice";
import ProjectManagementSlice from "./ProjectManagementSlice";
import TestSuitesSlice from "./TestSuitesSlice";
import SettingManagementSlice from "./SettingManagementSlice"
import AdminOrganizationSlice from "./AdminOrganizationSlice"
import AdminUserSlice from "./AdminUserSlice"
import AdminProjectSlice from "./AdminProjectSlice"
import AdminProjectSettingSlice from "./AdminProjectSettingSlice"
import AdminTestSuiteSlice from "./AdminTestSuiteSlice"
import AdminTastCaseSlice from "./AdminTastCaseSlice"

export const store = configureStore({
  reducer: {
    UserAuthenticationSlice,
    project:ProjectManagementSlice,
    TestSuites:TestSuitesSlice,
    SettingManagementSlice,
    AdminOrganizationSlice,
    AdminUserSlice,
    AdminProjectSlice,
    AdminProjectSettingSlice,
    AdminTestSuiteSlice,
    AdminTastCaseSlice
  },
});
