import "./AddOrganization.scss"
import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import queryString from "query-string";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  createOrganization,
  removeorganizationcreateErrMsg,
  removeorganizationcreateMsg
} from "../../../../Redux/AdminOrganizationSlice";
export default function AddOrganization() {
  const dispatch = useDispatch();
  const history = useHistory();
  const {status,createOrganizationMsg,createOrganizationErrMsg} = useSelector(
    (state) => state?.AdminOrganizationSlice
  );
  let project_id = queryString?.parse(history?.location?.search)?.id || 0;

  const [inputBoxData, setInputBoxData] = useState({
    org_name: "",
    country: "",
  });
  const [inputErrorData, setInputErrorData] = useState({
    org_name: "",
    country: "",
  });
  const handleChange = (e) => {
    let temp = { ...inputBoxData };
    temp[e.target.name] = e.target.value;
    setInputBoxData(temp);
  };
  const handleErrorRemove = (e) => {
    let temp = { ...inputErrorData };
    temp[e.target.name] = "";
    setInputErrorData(temp);
  };

  const validate = () => {
    let isError = false;
    let temp = { ...inputErrorData };

    if (inputBoxData?.org_name === "") {
      temp.org_name = "Organization name can not be empty";
      isError = true;
    }
    if (inputBoxData?.country === "") {
      temp.country = "Country can not be empty";
      isError = true;
    }
    setInputErrorData(temp);
    if (isError) window.scroll(0, 0);
    return isError;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var formData = new FormData();
    let isError = validate();
    if (!isError) {
    //   Object.entries(inputBoxData).forEach(([key, value]) => {
    //     formData.append(key, value);
    // });

      // console.log(...formData);
      const data ={
        org_name:inputBoxData?.org_name,
        country:inputBoxData?.country
      } 




      dispatch(createOrganization(data));
    }
  };

  useEffect(() => {
    if(createOrganizationMsg){
      toast.success(createOrganizationMsg)
      dispatch(removeorganizationcreateMsg())
    }
    if(createOrganizationErrMsg){
      toast.error(createOrganizationErrMsg)
      dispatch(removeorganizationcreateErrMsg())
    }
  }, [createOrganizationMsg,createOrganizationErrMsg])
    return (
      <div>
      <div className="settingOuter">
        <h2>Add Organization</h2>
        <hr/>
        <form onSubmit={handleSubmit} autocomplete="off">
          <div className="formInner">
            <div className="form-group py-1">
              <label>Organization Name</label>
              <div className="fieldWrapper">
                <input
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.org_name}
                  type="text"
                  name="org_name"
                  placeholder="Enter Organization Name"
                />
                {!!inputErrorData?.org_name && (
                  <div className="error_danger">
                    {inputErrorData?.org_name}
                  </div>
                )}
              </div>
            </div>

            <div className="form-group py-1">
              <label>Country</label>
              <div className="fieldWrapper">
                <input
                  type="text"
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.country}
                  name="country"
                  placeholder="Enter Country"
                />
                {!!inputErrorData?.country && (
                  <div className="error_danger">{inputErrorData?.country}</div>
                )}
              </div>
            </div>
            <div className="btnwrap">
              <button type="submit" className="btn"
               disabled={status === "loading"}
              >
              {status === "loading" && (
                <>
                  <i className="fas fa-spinner fa-pulse " />
                  &nbsp;
                </>
              )}
                Submit
              </button>
              <Link to="admin-organizations" className="btn mx-2"> <i className="fas fa-chevron-left"></i> Back</Link>
            </div>
          </div>
        </form>
      </div>
    </div>
    )
}
