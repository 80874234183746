import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiHelperFunction } from "../helpers/apiHelper";
import { useSelector, useDispatch } from "react-redux";

export const createTestCase = createAsyncThunk(
  "project/createTestCase",
  async (formData) => {
    
    const response = await ApiHelperFunction({
      urlPath: "api/test_case/insert",
      method: "post",
      formData: formData,
    });
    let resData = response?.data;
    // console.log(resData)
    return resData;
  }
);

export const fetchTestCase = createAsyncThunk(
  "project/fetchTestCase",
  async (formData) => {
    // console.log(formData)
    const response = await ApiHelperFunction({
      urlPath: "api/test_Case/list-advance",
      method: "post",
      formData: formData,
    });
    let resData = response?.data;
    return resData;
  }
);

export const updateTestCase = createAsyncThunk(
  "project/updateTestCase",
  async (formData) => {
    console.log(formData);
    const response = await ApiHelperFunction({
      urlPath: `api/test_case/update/${formData?.id}`,
      method: "post",
      formData: formData,
    });
    let resData = response?.data;
    return resData;
  }
);

export const singleTestCaseFetch = createAsyncThunk(
  "project/singleTestCaseFetch",
  async (id) => {
    const response = await ApiHelperFunction({
      urlPath: `api/test_case/details/${id}`,
      method: "get",
    });
    let resData = response?.data;
    console.log("data", resData);
    return resData;
  }
);


export const deleteTestCase = createAsyncThunk(
  "project/deleteTestCase",
  async (formData) => {
    const response = await ApiHelperFunction({
      urlPath: `api/test_Case/delete`,
      method: "post",
      formData: formData,
    });
    let resData = response?.data;
    return resData;
  }
);




export const alltestsuitefetch = createAsyncThunk(
    "project/alltestsuitefetch",
    async () => {
      const response = await ApiHelperFunction({
        urlPath: `api/test_suite/list`,
        method: "get",
        // formData: formData,
      });
      let resData = response?.data;
      return resData;
    }
  );

const initialState = {
  status: "idle",
  createTestCaseMsg: "",
  createTestCaseErrMsg: "",
  TestCaseList: [],
  totalPage: "",
  currantPage: "",
  updateTestCaseMsg: "",
  updateTestCaseErrMsg: "",
  singleTestCase: "",
  deleteTestCaseMsg: "",
  allTestSuite:[]
};
export const mainSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    removeAll: (state) => {
      state = initialState;
    },
    removeCreateTestCaseMsg: (state) => {
      state.createTestCaseMsg = "";
    },
    removeCreateTestCaseErrMsg: (state) => {
      state.createTestCaseErrMsg = "";
    },
    removeUpdateTestCaseMsg: (state) => {
      state.updateTestCaseMsg = "";
    },
    removeUpdateTestCaseErrMsg: (state) => {
      state.updateTestCaseErrMsg = "";
    },
    removeTestCaseMsg: (state) => {
      state.deleteTestCaseMsg = "";
    },

    
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchTestCase.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTestCase.fulfilled, (state, action) => {
        state.status = "idle";
        console.log(action.payload);
        if (action.payload != undefined) {
          if (action.payload.status === 200) {
            state.TestCaseList = action?.payload?.data?.docs;
            state.totalPage = action.payload.data?.pages;
            state.currantPage = action.payload.data?.page;
          } else {
            state.createTestCaseErrMsg = action.payload.message;
          }
        } else {
          state.createTestCaseErrMsg = "Something went to wrong !";
        }
      })
      .addCase(createTestCase.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createTestCase.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload != undefined) {
          if (action.payload.status === 200) {
            state.createTestCaseMsg = action.payload.message;
          } else {
            state.createTestCaseErrMsg = action.payload.message;
          }
        } else {
          state.createTestCaseErrMsg = "Something went to wrong !";
        }
      })
      .addCase(updateTestCase.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateTestCase.fulfilled, (state, action) => {
        state.status = "idle";
        console.log(action.payload);
        if (action.payload != undefined) {
          if (action.payload.status === 200) {
            state.updateTestCaseMsg = action.payload.message;
            state.TestCaseList = action?.payload?.dataWithPage?.docs;
            state.totalPage = action.payload.dataWithPage?.pages;
            state.currantPage = action.payload.dataWithPage?.page;
          } else {
            state.updateTestCaseErrMsg = action.payload.message;
          }
        } else {
          state.updateTestCaseErrMsg = "Something went to wrong !";
        }
      })

      .addCase(singleTestCaseFetch.pending, (state) => {
        state.status = "loading";
      })
      .addCase(singleTestCaseFetch.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload != undefined) {
          if (action.payload.status === 200) {
            state.singleTestCase = action.payload.data;
          } else {
            state.updateTestCaseErrMsg = action.payload.message;
          }
        } else {
          state.updateTestCaseErrMsg = "Something went to wrong !";
        }
      })
      .addCase(deleteTestCase.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteTestCase.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload != undefined) {
          if (action.payload.status === 200) {
            state.deleteTestCaseMsg = action.payload.message;
            state.TestCaseList = action?.payload?.data?.docs;
            state.totalPage = action.payload.data?.pages;
            state.currantPage = action.payload.data?.page;
          } else {
            state.updateTestCaseErrMsg = action.payload.message;
          }
        } else {
          state.updateTestCaseErrMsg = "Something went to wrong !";
        }
      })

      .addCase(alltestsuitefetch.pending, (state) => {
        state.status = "loading";
      })
      .addCase(alltestsuitefetch.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload != undefined) {
          if (action.payload.status === 200) {
            state.allTestSuite = action.payload.data;
         
          } else {
            // state.updateTestCaseErrMsg = action.payload.message;
          }
        } else {
        //   state.updateTestCaseErrMsg = "Something went to wrong !";
        }
      });
  },
});
export const {
  removeAll,
  removeCreateTestCaseMsg,
  removeCreateTestCaseErrMsg,
  removeUpdateTestCaseMsg,
  removeUpdateTestCaseErrMsg,
  removeTestCaseMsg
} = mainSlice.actions;
export default mainSlice.reducer;
