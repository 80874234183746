import React from 'react'
import "./Footer.scss";
export default function Footer() {
  return (
    <div>
      <footer className="footer">
         <p>Copyright. Spotline Inc.</p> 
      </footer>      
    </div>
  )
}
