import "./Users.scss";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import {
  fetchUser,
  clearuserErrorMsg,
  clearUserDeleteMsg,
  deleteUser,
} from "../../../Redux/AdminUserSlice";
import { toast } from "react-toastify";
import SweetAlertComponent from "../../../Share_Module/SweetAlertComponent/SweetAlertComponent";
import ReactPaginate from "react-paginate";
import TableSkeleton from "../../../Share_Module/Skeleton/TableSkeleton";
// import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// import EditIcon from '@mui/icons-material/Edit';
import Skeleton from '@mui/material/Skeleton';

function Users() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isDelete, setIsdelete] = useState(false);
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [currantP, setCurrantpage] = useState(1);
  const [query, setQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sort_field, setSortField] = useState("first_name")
  const {
    status,
    userList,
    userErrorMsg,
    totalPage,
    currantPage,
    deleteuserMsg,
    userDeletMsg,
  } = useSelector((state) => state?.AdminUserSlice);
  useEffect(() => {
    if (totalPage) {
      setPageCount(totalPage);
    }
    if (currantPage) {
      setCurrantpage(currantPage);
    }
  }, [totalPage, currantPage]);
  const deleteuser = (name, id) => {
    setUserName(name);
    setUserId(id);
    setIsdelete(true);
  };
  const handleCloneConfirm = () => {
    if (userId !== "") {
      dispatch(deleteUser({  id: userId, 
        page: currantP,
        sort_field: sort_field,
        sort_order: sortOrder, }));
    }
    setUserName("");
    setUserId("");
    setIsdelete(false);
  };
  useEffect(() => {
    if (userDeletMsg !== "") {
      toast.success(userDeletMsg);
      dispatch(clearUserDeleteMsg());
      // dispatch(fetchUser({page:currantP,query:query}));
    }
  }, [dispatch, userDeletMsg]);

  const handlePageClick = ({ selected }) => {
    setCurrantpage(selected + 1);
    dispatch(fetchUser( {
      page: selected + 1,
      query: query,
      sort_field: sort_field,
      sort_order: sortOrder,
    }));
   
  };

  const handleSearch = () => {
    if (query == "") {
      setCurrantpage(currantPage);
      dispatch(fetchUser({
        page: currantPage,
        query: query,
        sort_field: sort_field,
        sort_order: sortOrder,
      }));
      
    } else {
      setCurrantpage(1);
      dispatch(fetchUser( {
        page: currantP,
        query: query,
        sort_field: sort_field,
        sort_order: sortOrder,
      }));

     
    }
  };

  useEffect(() => {
    dispatch(fetchUser( {
      page: 1,
      query: query,
      sort_field: sort_field,
      sort_order: sortOrder,
    }));
  }, [dispatch]);

  const short = (sort_field1) => {
    setSortField(sort_field1)
    if (sortOrder === "asc") {
      setSortOrder("desc");
      dispatch(
        fetchUser({
          page: 1,
          query: query,
          sort_field: sort_field1,
          sort_order: "desc",
        })
      );
    } else {
      setSortOrder("asc");
      dispatch(
        fetchUser({
          page: 1,
          query: query,
          sort_field: sort_field1,
          sort_order: "asc",
        })
      );
    }
  };

  return (
    <div>
      <div className="addbtn">
        <Link to="admin-add-users" className="btn">
          Add User
        </Link>
      </div>
      <div className="searchFilter">
        <div className="searchBar">

          <input
            type="text"
            placeholder="Search"
            value={query}
            onChange={(e) => {
              setQuery(e?.target?.value);
            }}
            onKeyPress={(event) => event.key === "Enter" && handleSearch()}
          />

          <button
            type="button"
            className="btn"
            onClick={(event) => handleSearch()}
          >
            <img src="images/search.svg" alt="" />
          </button>
        </div>
        <div className="filtersec">
          {/* <select name="" id="">
          <option value="">Option 1</option>
          <option value="">Option 2</option>
          <option value="">Option 3</option>
         </select> */}
        </div>
      </div>
      <div className="dataSection">
        <div className="table-responsive">
          <table>
            <thead>
              <tr>
                <th>
                  Name{" "}
                  <button type="button" onClick={() => short("first_name")}>
                    <img src="images/sorting.svg" alt="" />
                  </button>
                </th>
                <th>
                  Email Id{" "}
                  <button type="button" onClick={() => short("email")}>
                    <img src="images/sorting.svg" alt="" />
                  </button>
                </th>
                {/* <th>Phone Number<button type="button"><img src="images/sorting.svg" alt="" /></button></th> */}
                <th>Role</th>
                <th>Action</th>

              </tr>
            </thead>
            <tbody>
              {status === "loading" ? (
                [1,1,1,1,1,1]?.map((obj,i)=>
                  <tr className="" key={i}>
                  <td >
                    <Skeleton variant="text" width={"99%"} height={30} />
                  </td>
                  <td >
                    <Skeleton variant="text" width={"99%"} height={30} />
                  </td>
                  <td >
                    <Skeleton variant="text" width={"99%"} height={30} />
                  </td>
                  <td>
                    <Skeleton variant="text" width={"99%"} height={30} />
                  </td>
                </tr>
                 )
                // <TableSkeleton/>
              ) : userList?.length === 0 ? (
                <td colSpan="3">User not found!</td>
              ) : (
                userList
                  ?.filter((gs) => gs.isDeleted === false)
                  ?.map((userlist, key) => (
                    <tr key={key}>
                      <td>{`${userlist?.first_name} ${userlist?.last_name}`}</td>
                      <td>{`${userlist?.email}`}</td>
                      <td> {userlist?.is_admin?<>ADMIN</>:<>USER</>} </td>
                      <td>
                        {" "}
                        <Link to={`admin-edit-users?id=${userlist?._id}`}>
                          <i
                            className="fas fa-edit cursor-pointer"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Edit User"
                          ></i>
                        </Link>{" "}
                        &nbsp;&nbsp;
                        <i
                          className="fas fa-trash-alt cursor-pointer"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Delete User"
                          onClick={() => {
                            deleteuser(
                              userlist?.first_name + userlist?.last_name,
                              userlist?._id
                            );
                          }}
                        ></i>{" "}
                      </td>
                    </tr>
                  ))
              )}
            </tbody>
          </table>
        </div>
        <nav className="paginations" aria-label="Page navigation example">
          {/* <ul class="pagination">
        <li class="page-item"><a class="page-link" href="#">Previous</a></li>
        <li class="page-item"><a class="page-link" href="#">1</a></li>
        <li class="page-item"><a class="page-link" href="#">2</a></li>
        <li class="page-item"><a class="page-link" href="#">3</a></li>
        <li class="page-item"><a class="page-link" href="#">Next</a></li>
      </ul> */}

          <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            pageCount={pageCount}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
            forcePage={currantPage-1}
          />
        </nav>
      </div>
      {isDelete && (
        <SweetAlertComponent
          confirm={handleCloneConfirm}
          cancle={() => setIsdelete(false)}
          title={"Are you sure?"}
          subtitle={`you want to Delete the Organization ${userName}`}
          type="danger"
        />
      )}
    </div>
  );
}
export default Users;
