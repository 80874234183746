import React, { useState, useEffect } from "react";
import "./Register.scss";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import {
  signup,
  clearRegisterMsg,
  clearRegisterErrorMsg,
  organizetionList,
  removeOrganizetionErrorMsg,
} from "../../Redux/UserAuthenticationSlice";
import { toast } from "react-toastify";
import { NavigateBeforeSharp } from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { MenuProps, useStyles,CustomCheckbox } from "./Utils";

function Register() {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    registerMsg,
    registerErrorMsg,
    organizetionArray,
    organizetionErrorMsg,
  } = useSelector((state) => state?.UserAuthenticationSlice);

  const options =organizetionArray

  // console.log(organizetionArray)

  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [orgValue, setorgValue] = useState([]);
  const [orgid, setorgid] = useState([]);


  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  const handleChange1 = (event) => {
   const value = event.target.value;

   let orgName =value&&value.map((obj,i)=>{
       return obj.org_name;
    })

    let id = value&& value.map((object,i)=>{
      return object._id;
   })
   
    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : options);
      return;
    }
   // console.log(orgName)
    setSelected(value);
    setorgValue(orgName);
    setorgid(id)
  };
  // console.log(selected)
  const [organizetion, setOrganizetion] = useState([]);
  const [inputBoxData, setInputBoxData] = useState({
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    phone: "",
    confirm_password: "",
  });
  const [inputErrorData, setInputErrorData] = useState({
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    phone: "",
    confirm_password: "",
    organizetion: "",
  });

  const [showPass, setShowPass] = useState({
    password: false,
    confirm_password: false,
  });
  const handlePassVisibility = (name) => {
    const temp = { ...showPass };
    temp[name] = !temp[name];
    setShowPass(temp);
  };
  useEffect(() => {
    if (registerMsg != "") {
      toast.success(registerMsg);
      history.push("/login");
    }
    if (registerErrorMsg != "") {
      toast.error(registerErrorMsg);
    }
    return () => {
      dispatch(clearRegisterMsg());
      dispatch(clearRegisterErrorMsg());
    };
  }, [registerMsg, registerErrorMsg]);

  const handleChange = (e) => {
    let temp = { ...inputBoxData };
    temp[e.target.name] = e.target.value;
    setInputBoxData(temp);
  };
  const handleErrorRemove = (e) => {
    let temp = { ...inputErrorData };
    temp[e.target.name] = "";
    setInputErrorData(temp);
  };
  const validate = () => {
    const emailReg =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneReg = /^\d{10}$/;
    let isError = false;
    let temp = { ...inputErrorData };
    if (inputBoxData?.first_name === "") {
      temp.first_name = "First name can not be empty";
      isError = true;
    }
    if (inputBoxData?.last_name === "") {
      temp.last_name = "Last name can not be empty";
      isError = true;
    }
    if (inputBoxData?.phone === "") {
      temp.phone = "Phone number can not be empty";
      isError = true;
    } else if (!phoneReg.test(inputBoxData?.phone)) {
      temp.phone = "Phone number must be a valid phone number.";
      isError = true;
    }
    if (inputBoxData?.email === "") {
      temp.email = "Email can not be empty";
      isError = true;
    } else if (!emailReg.test(inputBoxData?.email)) {
      temp.email = "Email must be a valid email address.";
      isError = true;
    }
    if (inputBoxData?.password === "") {
      temp.password = "Password can not be empty";
      isError = true;
    }
    if (inputBoxData?.confirm_password === "") {
      temp.confirm_password = "confirm password can not be empty";
      isError = true;
    } else if (inputBoxData?.password !== inputBoxData?.confirm_password) {
      temp.confirm_password =
        "Password and confirm password can not be different";
      isError = true;
    }
    if (orgid.length === 0) {
      temp.organizetion = "Please Select Organization ";
      isError = true;
    }
    setInputErrorData(temp);
    if (isError) window.scroll(0, 0);
    return isError;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    var formData = new FormData();
    let isError = validate();
    if (!isError) {
      //   Object.entries(inputBoxData).forEach(([key, value]) => {
      //     formData.append(key, value);
      // });
      const data = {
        email: inputBoxData?.email,
        password: inputBoxData?.password,
        first_name: inputBoxData?.first_name,
        last_name: inputBoxData?.last_name,
        phone: inputBoxData?.phone,
        org_code: orgid,
      };
      dispatch(signup(data));
    }
  };
  useEffect(() => {
    dispatch(organizetionList());
    return () => {
      dispatch(removeOrganizetionErrorMsg());
    };
  }, [dispatch]);

  const orgHandel = (e) => {
    if (e.target.checked) {
      organizetion.push(e.target.value);
    } else {
      const array = organizetion;
      const index = array.indexOf(e.target.value);
      if (index > -1) {
        array.splice(index, 1);
      }
      setOrganizetion(array);
    }
  };

  return (
    <>
      <div className="registerpg">
        <div className="heading">
          <h2>Register</h2>
        </div>
        <form onSubmit={handleSubmit} autocomplete="off">
          <div className="loginInner">
            <div className="form-group py-1">
              <label>First Name</label>
              <div className="fieldWrapper">
                <input
                  type="text"
                  name="first_name"
                  placeholder="Enter your First Name"
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.first_name}
                />
                {!!inputErrorData?.first_name && (
                  <div className="error_danger">
                    {inputErrorData?.first_name}
                  </div>
                )}
              </div>
            </div>
            <div className="form-group py-1">
              <label>Last Name</label>
              <div className="fieldWrapper">
                <input
                  type="text"
                  name="last_name"
                  placeholder="Enter your Last Name"
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.last_name}
                />
                {!!inputErrorData?.last_name && (
                  <div className="error_danger">
                    {inputErrorData?.last_name}
                  </div>
                )}
              </div>
            </div>

            <div className="form-group py-1">
              <label>Email ID</label>
              <div className="fieldWrapper">
                <input
                  type="text"
                  name="email"
                  placeholder="Enter your Email ID "
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.email}
                />
                {!!inputErrorData?.email && (
                  <div className="error_danger">{inputErrorData?.email}</div>
                )}
              </div>
            </div>

            <div className="form-group py-1">
              <label>Phone Number</label>
              <div className="fieldWrapper">
                <input
                  type="text"
                  name="phone"
                  placeholder="Enter your Phone Number"
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.phone}
                  className="textPassword"
                />
                {!!inputErrorData?.phone && (
                  <div className="error_danger">{inputErrorData?.phone}</div>
                )}
              </div>
            </div>

            <div className="form-group py-1">
              <label>Password</label>
              <div className="fieldWrapper textPassword">
                <input
                  type={showPass?.password ? "text" : "password"}
                  name="password"
                  placeholder="Enter your Password"
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.password}
                />
                <span
                  className="px-1 cp iconPassword"
                  onClick={() => handlePassVisibility("password")}
                >
                  {showPass?.password ? <Visibility /> : <VisibilityOff />}
                </span>

                {!!inputErrorData?.password && (
                  <div className="error_danger">{inputErrorData?.password}</div>
                )}
              </div>
            </div>

            <div className="form-group py-1">
              <label>Confirm Password</label>
              <div className="fieldWrapper textPassword">
                <input
                  type={showPass?.confirm_password ? "text" : "password"}
                  name="confirm_password"
                  placeholder="Enter your Confirm Password"
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.confirm_password}
                  className=""
                />
                <span
                  className="px-1 cp iconPassword"
                  onClick={() => handlePassVisibility("confirm_password")}
                >
                  {showPass?.confirm_password ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </span>

                {!!inputErrorData?.confirm_password && (
                  <div className="error_danger">
                    {inputErrorData?.confirm_password}
                  </div>
                )}
              </div>
            </div>

            <div className="form-group py-1">
              <label>Organization</label>
              <div className="fieldWrapper multiSelect">
                <FormControl className={classes.formControl}>
                  {/* <InputLabel id="mutiple-select-label">Multiple Select</InputLabel> */}
                  <Select
                  
                    className={classes.underline}
                    labelId="mutiple-select-label"
                    multiple
                    value={selected}
                    onChange={handleChange1}
                    renderValue={(selected) => {
                      let orgName =selected&&selected.map((obj,i)=>{
                        return obj.org_name;
                     })
                     return (orgName).join(", ")
                    }
                  }
                    placeholder="Select Organization"
                    MenuProps={MenuProps}
                    variant="filled"
                  >
                    <MenuItem
                      value="all"
                      classes={{
                        root: isAllSelected ? classes.selectedAll : "",
                      }}
                    >
                      <ListItemIcon>
                        <CustomCheckbox
                          classes={{
                            indeterminate: classes.indeterminateColor,
                          }}
                          checked={isAllSelected}
                          indeterminate={
                            selected.length > 0 &&
                            selected.length < options.length
                          }
                        />
                      </ListItemIcon>
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>
                    {options.map((option) => (
                      <MenuItem key={option} value={option}>
                        <ListItemIcon>
                          <CustomCheckbox checked={selected.indexOf(option) > -1} 
                           labelStyle={{color: 'white'}}
                           iconStyle={{fill: 'white'}}
                          />
                        </ListItemIcon>
                        <ListItemText primary={option.org_name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {!!inputErrorData?.organizetion && (
                  <div className="error_danger">
                    {inputErrorData?.organizetion}
                  </div>
                )}
              </div>
            </div>



            {/* <div className="form-group py-1">
              <label>Organization</label>
              <div className="fieldWrapper textPassword">
                {organizetionArray
                  .filter(
                    (gs) => gs.status === "Active" && gs.isDeleted === false
                  )
                  .map((object, i) => (
                    <>
                      <label
                        class="checkboxcontainer"
                        key={object._id}
                        for={object?._id}
                      >
                        {object?.org_name}
                        <input
                          type="checkbox"
                          name="remember_me"
                          // checked={inputBoxData?.remember_me}
                          onChange={orgHandel}
                          value={object?._id}
                          id={object?._id}
                        />
                        <span class="checkmark"></span>
                      </label>{" "}
                      &nbsp; &nbsp;
                    </>
                  ))}

                {!!inputErrorData?.organizetion && (
                  <div className="error_danger">
                    {inputErrorData?.organizetion}
                  </div>
                )}
              </div>
            </div> */}

            <div className="form-group py-1">
              <label>&nbsp;</label>
              <div className="fieldWrapper">
                <div className="rememberLoginbtn">
                  <label class="checkboxcontainer">
                    {" "}
                    {/* Remember */}
                    {/* <input type="checkbox" /> */}
                    {/* <span class="checkmark"></span> */}
                  </label>
                  <div className="btnForgotPass">
                    <button type="submit" className="btn shadow-none">
                      Register
                    </button>
                    <p>
                      <Link to="/login">Already I have an Account </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="copyrighttxt">
              <p>Copyright. Spotline Inc.</p>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default Register;
