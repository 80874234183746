import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { ApiHelperFunction } from "../helpers/apiHelper";
export const fetchProject = createAsyncThunk(
  "project/projectFetch",
  async () => {
    const response = await ApiHelperFunction({
      urlPath: "api/project/list",
      method: "get",
    });
    let resData = response?.data;
    return resData;
  }
);

export const fetchSingleProject = createAsyncThunk(
  "project/projectSingleFetch",
  async (id) => {
    const response = await ApiHelperFunction({
      urlPath: `api/project/list-by-org/${id}`,
      method: "get",
    });
    let resData = response?.data;
    return resData;
  }
);

const initialState = {
  status: "idle",
  projectList: [],
  singleProjectList: [],
  singleProjectErrMsg: "",
  projectErrMsg: "",
  projectName: "",
};

export const mainSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    removeAll: (state) => {
      state = initialState;
    },
    clearProjectErrorMsg: (state) => {
      state.projectErrMsg = "";
    },
    clearSingleProjectErrorMsg: (state) => {
      state.singleProjectErrMsg = "";
    },
    setProjectName: (state, action) => {
      localStorage.setItem("projectName", action.payload);
      state.projectName = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchProject.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProject.fulfilled, (state, action) => {
        state.status = "idle";
        // console.log(action.payload)
        if (action.payload != undefined) {
          if (action.payload.status === 200) {
            state.projectList = action.payload.data;
          } else {
            state.projectErrMsg = action.payload.message;
          }
        } else {
          //state.projectErrMsg = "Something went to wrong !";
        }
      })
      .addCase(fetchSingleProject.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSingleProject.fulfilled, (state, action) => {
        state.status = "idle";
        //  console.log(action.payload)
        if (action.payload != undefined) {
          if (action.payload.status === 200) {
            state.singleProjectList = action.payload.data;
          } else {
            state.singleProjectErrMsg = action.payload.message;
          }
        } else {
          state.projectErrMsg = "Something went to wrong !";
        }
      });
  },
});

export const {
  removeAll,
  clearProjectErrorMsg,
  clearSingleProjectErrorMsg,
  setProjectName,
} = mainSlice.actions;
export default mainSlice.reducer;
