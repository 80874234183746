import "./ChangePassword.scss";
import React,{useState,useEffect} from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    changePassword,
    removechangePasswordMsg,
   removeremovePasswordErrorMsg
  } from "../../../Redux/UserAuthenticationSlice";
  import { toast } from "react-toastify";
  import { setLocaleStorage } from "../../../helpers/apiHelper";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
function ChangePassword() {
    const history = useHistory();
    const dispatch = useDispatch();
    const {status,changePasswordErrMsg,changePasswordMsg} = useSelector(
        (state) => state?.UserAuthenticationSlice
    );
    const [inputBoxData, setInputBoxData] = useState({
        password: "",
        confirm_password: "",
    });
    const [inputErrorData, setInputErrorData] = useState({
      password: "",
      confirm_password: "",
    });

      const [showPass, setShowPass] = useState({
        password: false,
        confirm_password: false,
      });
      const handlePassVisibility = (name) => {
        const temp = { ...showPass };
        temp[name] = !temp[name];
        setShowPass(temp);
      };

      const handleChange = (e) => {
        let temp = { ...inputBoxData };
        temp[e.target.name] = e.target.value;
        setInputBoxData(temp);
      };
      const handleErrorRemove = (e) => {
        let temp = { ...inputErrorData };
        temp[e.target.name] = "";
        setInputErrorData(temp);
      };
      useEffect(() => {
        if(changePasswordMsg !=""){
          toast.success(changePasswordMsg);
        }
        if(changePasswordErrMsg !=""){
          toast.error(changePasswordErrMsg);
        }
        return () => {
          dispatch(removechangePasswordMsg());
          dispatch(removeremovePasswordErrorMsg());
        }
      }, [changePasswordMsg,changePasswordErrMsg])

      const validate = () => {
        let isError = false;
        let temp = { ...inputErrorData };
        if (inputBoxData?.password === "") {
          temp.email = "Password can not be empty";
          isError = true;
        }
        if (inputBoxData?.confirm_password === "") {
            temp.confirm_password = " Confirm Password can not be empty";
            isError = true;
        }else if(inputBoxData?.confirm_password != inputBoxData?.password){
            temp.confirm_password = "Password and Confirm password Did not match";
            isError = true;
        }
        setInputErrorData(temp);
        if (isError) window.scroll(0, 0);
        return isError;
      };

    const handleSubmit = (e) => {
        e.preventDefault();
        var formData = new FormData();
        let isError = validate();
        if (!isError) {
          Object.entries(inputBoxData).forEach(([key, value]) => {
            formData.append(key, value);
          });
          dispatch(changePassword(formData));
          setInputBoxData({
            password: "",
            confirm_password: "",
          });
          setInputErrorData({
            password: "",
            confirm_password: "",
          });
        }
    };
  return (
    <div>
      <div className="changepasswordpass">
        <div className="heading">
          <h2>Change Password</h2>
        </div>
        <form onSubmit={handleSubmit} autocomplete="off">
        <div className="loginInner">
          <div className="form-group py-1 textPassword">
            <label>New Password</label>
            <div className="fieldWrapper">
              <input 
              type={
                showPass?.password ? "text" : "password"
              } 
              name="password"
              onChange={handleChange}
              onFocus={handleErrorRemove}
              value={inputBoxData?.password}
              placeholder="Enter new Password"
              />
               <span
                  className="px-1 cp iconPassword"
                  onClick={() =>
                    handlePassVisibility("password")
                  }
                >
                  {showPass?.password ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </span>
               {!!inputErrorData?.password && (
                  <div className="error_danger">
                    {inputErrorData?.password}
                  </div>
                )}
            </div>
          </div>
          <div className="form-group py-1 textPassword">
            <label>Confirm Password</label>
            <div className="fieldWrapper">
              <input 
                type={
                  showPass?.confirm_password ? "text" : "password"
                } 
               name="confirm_password"
               onChange={handleChange}
               onFocus={handleErrorRemove}
               value={inputBoxData?.confirm_password}
               placeholder="Enter Confirm Password"
              />
               <span
                  className="px-1 cp iconPassword"
                  onClick={() =>
                    handlePassVisibility("confirm_password")
                  }
                >
                  {showPass?.confirm_password ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </span>
               {!!inputErrorData?.confirm_password && (
                  <div className="error_danger">
                    {inputErrorData?.confirm_password}
                  </div>
                )}
            </div>
          </div>
          <div className="Submitbtn">
          <button type="submit" className="btn"
               disabled={status === "loading"}
              >
              {status === "loading" && (
                <>
                  <i className="fas fa-spinner fa-pulse " />
                  &nbsp;
                </>
              )}
                Update
              </button>
          </div>
          {/* <div className="copyrighttxt">
            <p>Copyright. Spotline Inc.</p>
          </div> */}
        </div>
        </form>
      </div>
    </div>
  );
}
export default ChangePassword;
