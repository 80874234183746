import "./ForgotPassword.scss";
import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  forgotPassword,
  removeforgotpasswordErrorMsg,
  removeforgotpasswordMsg,
} from "../../Redux/UserAuthenticationSlice";
import { toast } from "react-toastify";

function ForgotPassword() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [inputBoxData, setInputBoxData] = useState({
    email: "",
  });
  const [inputErrorData, setInputErrorData] = useState({
    email: "",
  });
  const { forgotpasswordMsg, forgotpasswordErrMsg } = useSelector(
    (state) => state?.UserAuthenticationSlice
  );
  const handleChange = (e) => {
    let temp = { ...inputBoxData };
    temp[e.target.name] = e.target.value;
    setInputBoxData(temp);
  };
  const handleErrorRemove = (e) => {
    let temp = { ...inputErrorData };
    temp[e.target.name] = "";
    setInputErrorData(temp);
  };

  const validate = () => {
    let isError = false;
    let temp = { ...inputErrorData };
    if (inputBoxData?.email === "") {
      temp.email = "Email Id can not be empty";
      isError = true;
    }
    setInputErrorData(temp);
    if (isError) window.scroll(0, 0);
    return isError;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var formData = new FormData();
    let isError = validate();
    if (!isError) {
      Object.entries(inputBoxData).forEach(([key, value]) => {
        formData.append(key, value);
      });
      dispatch(forgotPassword(formData));
      setInputBoxData({
        email: "",
      });
      setInputErrorData({
        email: "",
      });
    }
  };

  useEffect(() => {
    if (forgotpasswordMsg != "") {
      toast.success(forgotpasswordMsg);
      // history.push("/login");
    }
    if (forgotpasswordErrMsg != "") {
      toast.error(forgotpasswordErrMsg);
    }
    return () => {
      dispatch(removeforgotpasswordMsg());
      dispatch(removeforgotpasswordErrorMsg());
    };
  }, [forgotpasswordMsg, forgotpasswordErrMsg]);

  return (
    <div>
      <div className="forgotpass">
        <div className="heading">
          <h2>Forgot Password</h2>
        </div>
        <form onSubmit={handleSubmit} autocomplete="off">
          <div className="loginInner">
            <div className="form-group">
              <label>Email Id</label>
              <div className="fieldWrapper">
                <input
                  type="text"
                  name="email"
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.email}
                  placeholder="Enter your Email id"
                />
                {!!inputErrorData?.email && (
                  <div className="error_danger">{inputErrorData?.email}</div>
                )}
              </div>
            </div>
            {/* <div className="form-group">
            <label>Confirm Password</label>
            <div className="fieldWrapper">
              <input type="password" />
            </div>
          </div> */}
            <div className="Submitbtn">
              <button type="submit" className="btn">
                Submit
              </button>
              <p>
                <Link to="/login">Login?</Link>
              </p>
            </div>
            <div className="copyrighttxt">
              <p>Copyright. Spotline Inc.</p>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
