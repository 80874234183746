import React from 'react'
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
function TestSuitesSkiliton() {
    return (
        <>
        {[1,2,3,4,5,6,7,8,9].map((object,i)=>
            <div className="AccordionOuter">
                <div className="playPauseRange">
                        <Skeleton variant="text" height={"80px"} />
                </div>
            </div>
        )}
        </>
    )
}

export default TestSuitesSkiliton
