import React from 'react';
import { Bar } from 'react-chartjs-2';

const data = {
  labels: ['january', 'February', 'March', 'April', 'Jun'],
  datasets: [
    {
      label: '# of Votes',
      data: [90, -19, 3, -5, 2, -81],
      backgroundColor: [
        'rgba(241,192,135, 0.8)',
        'rgba(241,192,135, 0.8)',
       
      ],
      borderColor: [
        'rgba(241,192,135, 1)',
        'rgba(241,192,135, 1)',
      ],
      borderWidth: 1,
    },
    {
        label: '# of Votes1',
        data: [-90, -9, 31, -51, 25, 81],
        backgroundColor: [
           
          'rgba(98,157,100, 0.8)',
          'rgba(98,157,100, 0.8)',
         
        ],
        borderColor: [
            'rgba(98,157,100, 1)',
            'rgba(98,157,100, 1)',
        ],
        borderWidth: 1,
      },
  ],
  
};

const options = {
  responsive: true,
  interaction: {
    intersect: false,
  },
  scales: {
    x: {
      stacked: false,
    },
    y: {
      stacked: false
    }
  },
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: '',
      
    }
  }
};

const VerticalBar = () => (
  <>
    
    <Bar
     data={data}
     options={options}
   

     />
  </>
);

export default VerticalBar;