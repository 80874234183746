

// import "./AddUser.scss"
import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import queryString from "query-string";
import { useSelector, useDispatch } from "react-redux";
// import {
//   projectSetting,
//   removeProjectSettingErrMsg,
//   removeProjectSettingMsg,
// } from "../../../Redux/SettingManagementSlice";
import { alltestsuitefetch,createTestCase,removeCreateTestCaseMsg,removeCreateTestCaseErrMsg} from "../../../../Redux/AdminTastCaseSlice";
import { toast } from "react-toastify";


export default function AddTestCases() {
  const history = useHistory();
  const dispatch=useDispatch();
  const {  status,allTestSuite,createTestCaseMsg,createTestCaseErrMsg} = useSelector(
    (state) => state?.AdminTastCaseSlice
  );
  let project_id = queryString?.parse(history?.location?.search)?.id || 0;
  const [inputBoxData, setInputBoxData] = useState({
    test_case_name: "",
    test_suite_code: "",
    release_no: "",
    release_date: "",
  });
  const [inputErrorData, setInputErrorData] = useState({
    test_case_name: "",
    test_suite_code: "",
    release_no: "",
    release_date: "",
  });

  const handleChange = (e) => {
    let temp = { ...inputBoxData };
    temp[e.target.name] = e.target.value;
    setInputBoxData(temp);
  };
  const handleErrorRemove = (e) => {
    let temp = { ...inputErrorData };
    temp[e.target.name] = "";
    setInputErrorData(temp);
  };

  const validate = () => {
    let isError = false;
    let temp = { ...inputErrorData };

    if (inputBoxData?.test_case_name === "") {
      temp.test_case_name = "Test case name can not be empty";
      isError = true;
    }
    if (inputBoxData?.test_suite_code === "") {
      temp.test_suite_code = "Test suite name can not be empty";
      isError = true;
    }
    if (inputBoxData?.release_no === "") {
      temp.release_no = "Release no can not be empty";
      isError = true;
    }
    if (inputBoxData?.release_date === "") {
      temp.release_date = "Release date can not be empty";
      isError = true;
    }

    setInputErrorData(temp);
    if (isError) window.scroll(0, 0);
    return isError;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var formData = new FormData();
    let isError = validate();
    if (!isError) {
      Object.entries(inputBoxData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      const data = {
        test_case_name: inputBoxData?.test_case_name,
        test_suite_code: inputBoxData?.test_suite_code,
        release_no: inputBoxData?.release_no,
        release_date: inputBoxData?.release_date,
      }
      dispatch(createTestCase(data));
     
    }
  };

  useEffect(() => {
    dispatch(alltestsuitefetch())
  }, [dispatch])

  useEffect(() => {
    if(createTestCaseMsg){
      toast.success(createTestCaseMsg)
    }
    if(createTestCaseErrMsg){
      toast.error(createTestCaseErrMsg)
    }
    dispatch(removeCreateTestCaseMsg())
    dispatch(removeCreateTestCaseErrMsg())
  }, [createTestCaseMsg,createTestCaseErrMsg])

    return (
      <div>
      <div className="settingOuter">
        <h2>Add Test Cases</h2>
        <hr/>
        <form onSubmit={handleSubmit} autocomplete="off">
          <div className="formInner">
          <div className="form-group py-1">
              <label>Select Test Suite</label>
              <div className="fieldWrapper">
              <select name="test_suite_code" id="test_suite_code"
                onChange={handleChange}
                onFocus={handleErrorRemove}>

                <option value="" disabled selected>Select Test Suite</option>

                {allTestSuite&&allTestSuite?.map((object,i)=>

                <option value={object?._id}>{object?.suite_name}</option>

                )}
              
                
              </select>
                {!!inputErrorData?.test_suite_code && (
                  <div className="error_danger">{inputErrorData?.test_suite_code}</div>
                )}
              </div>
            </div>
            <div className="form-group py-1">
              <label>Test Case Name</label>
              <div className="fieldWrapper">
                <input
                  type="text"
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.test_case_name}
                  name="test_case_name"
                  placeholder="Enter Test Case Name"
                />

                {!!inputErrorData?.test_case_name && (
                  <div className="error_danger">{inputErrorData?.test_case_name}</div>
                )}
              </div>
            </div>

            <div className="form-group py-1">
              <label>Release No</label>
              <div className="fieldWrapper">
                <input
                  type="text"
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.release_no}
                  name="release_no"
                  placeholder="Enter Release No"
                />
                {!!inputErrorData?.release_no && (
                  <div className="error_danger">{inputErrorData?.release_no}</div>
                )}
              </div>
            </div>

            <div className="form-group py-1">
              <label>Release Date</label>
              <div className="fieldWrapper">
                <input
                  type="date"
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.release_date}
                  name="release_date"
                  placeholder="Enter Release Date"
                />
                {!!inputErrorData?.release_date && (
                  <div className="error_danger">{inputErrorData?.release_date}</div>
                )}
              </div>
            </div>


            <div className="btnwrap">
            <button type="submit" className="btn"
               disabled={status === "loading"}
              >
              {status === "loading" && (
                <>
                  <i className="fas fa-spinner fa-pulse " />
                  &nbsp;
                </>
              )}
                Submit
              </button>
              <Link to="admin-testcases" className="btn mx-2"> <i className="fas fa-chevron-left"></i> Back</Link>

            </div>
          </div>
        </form>
      </div>
    </div>
    )
}



