import "./AddUser.scss";
import React, { useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";
import { toast } from "react-toastify";
import { NavigateBeforeSharp } from "@material-ui/icons";
import Checkbox from "@material-ui/core/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { MenuProps, useStyles, CustomCheckbox } from "./Utils";
// import {
//   projectSetting,
//   removeProjectSettingErrMsg,
//   removeProjectSettingMsg,
// } from "../../../Redux/SettingManagementSlice";
import { organizetionList } from "../../../../Redux/UserAuthenticationSlice";
import { fetchProject } from "../../../../Redux/ProjectManagementSlice";
import {
  addUser,
  clearaddUserMsg,
  clearaddUserErrMsg,
} from "../../../../Redux/AdminUserSlice";

export default function AddUser() {
  const history = useHistory();
  const dispatch = useDispatch();

  // const { projectSettingErrMsg, projectSettingMsg } = useSelector(
  //   (state) => state?.SettingManagementSlice
  // );

  const { organizetionArray } = useSelector(
    (state) => state?.UserAuthenticationSlice
  );
  const { projectList } = useSelector((state) => state?.project);

  const { status, userAddMsg, userAddErrMsg } = useSelector(
    (state) => state?.AdminUserSlice
  );

  let project_id = queryString?.parse(history?.location?.search)?.id || 0;
  const [inputBoxData, setInputBoxData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    org_code: "",
    notification_preference:"",
    is_admin:false
  });

  const [inputErrorData, setInputErrorData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    org_code: "",
    notification_preference:"",
    // project_code: "",
  });

  useEffect(() => {
    dispatch(organizetionList());
    dispatch(fetchProject());
  }, [dispatch]);

  const [selected, setSelected] = useState([]);
  const options =
    projectList &&
    projectList.filter(
      (gs) => gs.org_code == inputBoxData?.org_code && gs.isDeleted === false
    );

  const classes = useStyles();
  // const [selected, setSelected] = useState([]);
  const [projectValue, setProjectValue] = useState([]);
  const [projectid, setProjectid] = useState([]);
  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  const handleChange1 = (event) => {
    const value = event.target.value;

    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : options);
      return;
    }

    let projectName =
      value &&
      value.map((obj, i) => {
        return obj.project_name;
      });

    let id =
      value &&
      value.map((object, i) => {
        return object._id;
      });

    // console.log(projectName)

    setSelected(value);
    setProjectValue(projectName);
    setProjectid(id);
    handleErrorRemove(event);
  };
  const handleChange = (e) => {
    let temp = { ...inputBoxData };
    if (e.target.name === "is_admin") {
      temp[e.target.name] = e.target.checked;
    } else temp[e.target.name] = e.target.value;
    setInputBoxData(temp);
    if (e.target.name === "org_code") {
      setSelected([]);
      setProjectValue([]);
      setProjectid([]);
    }
  };
  const handleErrorRemove = (e) => {
    let temp = { ...inputErrorData };
   
      temp[e.target.name] = "";
  
    setInputErrorData(temp);
  };

  const validate = () => {
    let isError = false;
    let temp = { ...inputErrorData };
    const emailReg =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (inputBoxData?.first_name === "") {
      temp.first_name = "First name can not be empty";
      isError = true;
    }
    if (inputBoxData?.last_name === "") {
      temp.last_name = "Last name can not be empty";
      isError = true;
    }
    if (inputBoxData?.email === "") {
      temp.email = "Email can not be empty";
      isError = true;
    } else if (!emailReg.test(inputBoxData?.email)) {
      temp.email = "Email must be a valid email address.";
      isError = true;
    }
    if (inputBoxData?.org_code === "") {
      temp.org_code = "Organization can not be empty";
      isError = true;
    }
    if (inputBoxData?.notification_preference === "") {
      temp.notification_preference = "Notification preference can not be empty";
      isError = true;
    }
    setInputErrorData(temp);
    if (isError) window.scroll(0, 0);
    return isError;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    var formData = new FormData();
    let isError = validate();
    
    if (!isError) {
      Object.entries(inputBoxData).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("project_code", projectid);

      const data = {
        first_name: inputBoxData?.first_name,
        last_name: inputBoxData?.last_name,
        email: inputBoxData?.email,
        org_code: inputBoxData?.org_code,
        notification_preference: inputBoxData?.notification_preference,
        is_admin: inputBoxData?.is_admin,
      };

      dispatch(addUser(data));
    }
  };

  useEffect(() => {
    if (userAddMsg) {
      toast.success(userAddMsg);
    }
    if (userAddErrMsg) {
      toast.error(userAddErrMsg);
    }
    dispatch(clearaddUserMsg());
    dispatch(clearaddUserErrMsg());
  }, [userAddMsg, userAddErrMsg]);

  // console.log(projectValue)

  return (
    <div>
      <div className="settingOuter">
        <h2>Add User</h2>
        <hr />
        <form onSubmit={handleSubmit} autocomplete="off">
          <div className="formInner">
            <div className="form-group py-1">
              <label>Frist Name</label>
              <div className="fieldWrapper">
                <input
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.first_name}
                  type="text"
                  name="first_name"
                  placeholder="Enter Frist Name"
                />
                {!!inputErrorData?.first_name && (
                  <div className="error_danger">
                    {inputErrorData?.first_name}
                  </div>
                )}
              </div>
            </div>

            <div className="form-group py-1">
              <label>Last Name</label>
              <div className="fieldWrapper">
                <input
                  type="text"
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.last_name}
                  name="last_name"
                  placeholder="Enter Last Name"
                />
                {!!inputErrorData?.last_name && (
                  <div className="error_danger">
                    {inputErrorData?.last_name}
                  </div>
                )}
              </div>
            </div>

            <div className="form-group py-1">
              <label>Email Id</label>
              <div className="fieldWrapper">
                <input
                  type="text"
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.email}
                  name="email"
                  placeholder="Enter Email Id"
                />
                {!!inputErrorData?.email && (
                  <div className="error_danger">{inputErrorData?.email}</div>
                )}
              </div>
            </div>

            {/* <div className="form-group py-1">
              <label>Phone Number</label>
              <div className="fieldWrapper">
                <input
                  type="text"
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                  value={inputBoxData?.value}
                  name="value"
                  placeholder="Enter Phone Number"
                />
                {!!inputErrorData?.value && (
                  <div className="error_danger">{inputErrorData?.value}</div>
                )}
              </div>
            </div> */}

            <div className="form-group py-1">
              <label>Select Organization</label>
              <div className="fieldWrapper">
                <select
                  name="org_code"
                  id="org_code"
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                >
                  <option value="" disabled selected>
                    {" "}
                    Select Organization{" "}
                  </option>
                  {organizetionArray &&
                    organizetionArray?.filter(gs=>gs.isDeleted === false)?.map((org, index) => (
                      <option value={org._id} key={org._id}>
                        {org.org_name}
                      </option>
                    ))}
                </select>
                {!!inputErrorData?.org_code && (
                  <div className="error_danger">{inputErrorData?.org_code}</div>
                )}
              </div>
            </div>

            <div className="form-group py-1">
              <label>Notification Preference</label>
              <div className="fieldWrapper">
                <select
                  name="notification_preference"
                  id="notification_preference"
                  onChange={handleChange}
                  onFocus={handleErrorRemove}
                >
                  <option value="" disabled selected>
                    Select Notification Preference
                  </option>

                  <option value="Enable All Notifications">
                    Enable All Notifications
                  </option>
                  <option value="Notify on successful completions">
                    Notify on successful completions
                  </option>
                  <option value="Notify on errors only">
                    Notify on errors only
                  </option>
                  <option value="Do not Notify">Do not Notify</option>
                </select>
                {!!inputErrorData?.notification_preference && (
                  <div className="error_danger">{inputErrorData?.notification_preference}</div>
                )}
              </div>
          
            </div>

            
            <div className="form-group py-1">
              <label>Role</label>
              <div className="fieldWrapper">
              <label class="checkboxcontainer">
                    Is Admin
                    <input
                      type="checkbox"
                      name="is_admin"
                      checked={inputBoxData?.is_admin?true:false}
                      onChange={handleChange}
                    />
                    <span className="checkmark"></span>
                  </label>

              </div>
            </div>

            {/* 
            <div className="form-group py-1">
              <label>Select Project</label>
              <div className="fieldWrapper multiSelect">
                <FormControl className={classes.formControl}>
                  <InputLabel id="mutiple-select-label">Multiple Select</InputLabel>
                  <Select
                    className={classes.underline}
                    labelId="mutiple-select-label"
                    multiple
                    value={selected}
                    onChange={handleChange1}
                    onFocus={handleErrorRemove}
                    renderValue={(selected) => {
                      return projectValue.join(", ");
                    }}
                    placeholder="Select Organization"
                    MenuProps={MenuProps}
                    variant="filled"
                  >
                    <MenuItem
                      value="all"
                      classes={{
                        root: isAllSelected ? classes.selectedAll : "",
                      }}
                    >
                      <ListItemIcon>
                        <CustomCheckbox
                          classes={{
                            indeterminate: classes.indeterminateColor,
                          }}
                          checked={isAllSelected}
                          indeterminate={
                            selected.length > 0 &&
                            selected.length < options.length
                          }
                        />
                      </ListItemIcon> 
                      <ListItemText
                        classes={{ primary: classes.selectAllText }}
                        primary="Select All"
                      />
                    </MenuItem>

                    {options.map((option) => (
                      <MenuItem key={option} value={option}>
                        <ListItemIcon>
                          <CustomCheckbox
                            checked={selected.indexOf(option) > -1}
                            labelStyle={{ color: "white" }}
                            iconStyle={{ fill: "white" }}
                          />
                        </ListItemIcon>
                        <ListItemText primary={option.project_name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {!!inputErrorData?.project_code && (
                  <div className="error_danger">
                    {inputErrorData?.project_code}
                  </div>
                )}
              </div>
            </div> */}

            <div className="btnwrap">
            <button type="submit" className="btn"
               disabled={status === "loading"}
              >
              {status === "loading" && (
                <>
                  <i className="fas fa-spinner fa-pulse " />
                  &nbsp;
                </>
              )}
                Submit
              </button>

              <Link to="admin-users" className="btn mx-2">
                {" "}
                <i className="fas fa-chevron-left"></i> Back
              </Link>

            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
