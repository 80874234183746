import "./ProjectSetting.scss";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import {
  fetchProjectSetting,
  deleteProjectSetting,
  removeProjectSettingDeleteMsg
} from "../../../Redux/AdminProjectSettingSlice";
import { toast } from "react-toastify";
import SweetAlertComponent from "../../../Share_Module/SweetAlertComponent/SweetAlertComponent"
import ReactPaginate from "react-paginate";
import { imagePath } from "./../../../API/index";
import Skeleton from '@mui/material/Skeleton';

function ProjectSetting() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isDelete,setIsdelete]=useState(false);
  const [userName,setUserName]=useState("");
  const [userId,setUserId]=useState("");
  const [pageCount,setPageCount] = useState(0);
  const [currantP,setCurrantpage] = useState(1)
  const [query,setQuery] = useState("");
  const [sortOrder,setSortOrder] = useState("asc");
  const [sort_field,setSortField] = useState("setting_name")

  const { status, projectSettingList,totalPage,currantPage,projectSettingDeleteMsg} = useSelector(
    (state) => state?.AdminProjectSettingSlice
  );

  useEffect(() => {
    if(totalPage){
     setPageCount(totalPage)
    }
    if(currantPage){
     setCurrantpage(currantPage)
    }
   }, [totalPage,currantPage]);

   const deleteProject1 =(name,id)=>{
    setUserName(name)
    setUserId(id)
    setIsdelete(true)
  }

  const handleCloneConfirm = () => {
    if (userId !== "") {
        dispatch(deleteProjectSetting({ id: userId, 
          page: currantP,
          sort_field: sort_field,
          sort_order: sortOrder,}));

        
    }
    setUserName("")
    setUserId("")
    setIsdelete(false);
  };


  useEffect(() => {
    if (projectSettingDeleteMsg !== "") {
      toast.success(projectSettingDeleteMsg);
      dispatch(removeProjectSettingDeleteMsg());
    }
  }, [dispatch, projectSettingDeleteMsg]);

  const handlePageClick =({selected})=>{
    setCurrantpage(selected+1)
    dispatch(fetchProjectSetting({ page:selected+1,query:query,sort_field:sort_field,sort_order:sortOrder}));
   
  }
  const handleSearch = () =>{
    if (query == "") {
      setCurrantpage(currantPage);
      dispatch(fetchProjectSetting(  { page:currantPage,query:query,sort_field:sort_field,sort_order:sortOrder}));
    
    } else {
      setCurrantpage(1);
      dispatch(fetchProjectSetting({ page:1,query:query,sort_field:sort_field,sort_order:sortOrder}));
      
    }
  } 
  useEffect(() => {
    dispatch(fetchProjectSetting({ page:1,query:query,sort_field:sort_field,sort_order:sortOrder}));
  }, [dispatch]);

  const short=(sort_field1)=>{
    setSortField(sort_field1)
    if(sortOrder === "asc"){
      setSortOrder('desc')
      dispatch(fetchProjectSetting({page:1,query:query,sort_field:sort_field1,sort_order:"desc"})); 
    }else{
      setSortOrder('asc')
      dispatch(fetchProjectSetting({page:1,query:query,sort_field:sort_field1,sort_order:"asc"})); 
    }
    }



    // const statuschange = (id,status) =>{
    //   var formData = new FormData();
    //   formData.append("id", id);
    //   formData.append("status", status);
    //   dispatch(updateProject(formData));
    // }



    // useEffect(() => {
    //  if(projectUpdateMsg){
    //   toast.success("Status Change Successfully")
    //  }
    //  if(projectUpdateErrMsg){
    //   toast.error("Status Did Not change!")

    //  }
    //  dispatch(removeUpdateProjectMsg())
    //  dispatch(removeUpdateProjectErrMsg())
    //  dispatch(projectListing({ page: currantPage, query: query }));
    // }, [projectUpdateMsg,projectUpdateErrMsg])


    return (
        <div>
        <div className="addbtn">
          <Link to="admin-add-projectsetting" className="btn">
           Add Project Setting
          </Link>
        </div>
        <div className="searchFilter">
          <div className="searchBar">
          <input type="text" placeholder="Search" 
            value={query}
            onChange={(e) => {
              setQuery(e?.target?.value);
            }}
            onKeyPress={(event) =>
              event.key === "Enter" && handleSearch()
            }
          />
          <button type="button" className="btn"
            onClick={(event) => handleSearch()}
          >
          <img src="images/search.svg" alt="" />
        </button>
          </div>
          <div className="filtersec">
            {/* <select name="" id="">
              <option value="">Option 1</option>
              <option value="">Option 2</option>
              <option value="">Option 3</option>
            </select> */}
          </div>
        </div>
        <div className="dataSection">
          <div className="table-responsive"> 
            <table>
              <thead>
                <tr>
                  <th>Setting name <button type="button" onClick={()=>short("setting_name")}><img src="images/sorting.svg" alt="" /></button></th>
                  <th>Project name
                    {/* <button type="button" onClick={()=>short("project_name")}><img src="images/sorting.svg" alt="" /></button> */}
                  </th>
                  <th>Setting value<button type="button" onClick={()=>short("setting_value")}><img src="images/sorting.svg" alt="" /></button></th>
                
                  <th>Action</th>

                </tr>
              </thead>
              <tbody>
              {status === "loading" ? (
              [1,1,1,1,1,1]?.map((obj,i)=>
              <tr className="" key={i}>
              <td >
                <Skeleton variant="text" width={"99%"} height={30} />
              </td>
              <td >
                <Skeleton variant="text" width={"99%"} height={30} />
              </td>
              <td >
                <Skeleton variant="text" width={"99%"} height={30} />
              </td>
              <td >
                <Skeleton variant="text" width={"99%"} height={30} />
              </td>
             
             
            </tr>
             )
              ) : projectSettingList?.length === 0 ? (
                <td colSpan="3">User not found!</td>
              ) : (
                projectSettingList
                  ?.filter((gs) => gs.isDeleted === false)
                  ?.map((projectSettingList, key) => (
                <tr>
                  <td>{projectSettingList?.setting_name}</td>
                  <td>{projectSettingList?.project_data?.project_name}</td>
                  <td> {projectSettingList?.setting_value}</td>
                  <td> <Link to={`admin-edit-projectsetting?id=${projectSettingList?._id}`}><i className="fas fa-edit cursor-pointer" data-toggle="tooltip" data-placement="top" title="Edit Project Setting"></i></Link> &nbsp;&nbsp;  
                  <i className="fas fa-trash-alt cursor-pointer" data-toggle="tooltip" data-placement="top" title="Delete Project Setting" onClick={()=>{deleteProject1(projectSettingList?.setting_name,projectSettingList?._id)}}></i> </td>
                </tr>
                ))
                )}
              </tbody>
            </table>
          </div>
          <nav className="paginations" aria-label="Page navigation example">
          {/* <ul class="pagination">
            <li class="page-item"><a class="page-link" href="#">Previous</a></li>
            <li class="page-item"><a class="page-link" href="#">1</a></li>
            <li class="page-item"><a class="page-link" href="#">2</a></li>
            <li class="page-item"><a class="page-link" href="#">3</a></li>
            <li class="page-item"><a class="page-link" href="#">Next</a></li>
          </ul> */}

         <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
            forcePage={currantPage-1}
          />
        </nav>
        </div>
       
           {isDelete && (
        <SweetAlertComponent
          confirm={handleCloneConfirm}
          cancle={() => setIsdelete(false)}
          title={"Are you sure?"}
          subtitle={`you want to Delete the Organization ${userName}`}
          type="danger"
        />
      )}
      </div>
    )
}

export default ProjectSetting

