import "./SidebarPageWrapper.scss";
import React from "react";
import { useLocation } from "react-router-dom";

function SidebarPageWrapper(props) {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  return (
    // <div className={`sidebarPageWrapper`}>

    <div
      className={`sidebarPageWrapper ${
        splitLocation[splitLocation.length - 1] === "register" ||
        splitLocation[splitLocation.length - 1] === "login" ||
        splitLocation[splitLocation.length - 1] === "forgot-password"
          ? "active"
          : ""
      }`}
    >
      {props?.children}
    </div>
  );
}

export default SidebarPageWrapper;
