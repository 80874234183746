import "./Sidebar.scss";
import React from "react";
// import { Link } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import {isMobile} from 'react-device-detect';
import {Link, useLocation, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getStorage } from "./../../helpers/apiHelper";

function Sidebar(props) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state?.UserAuthenticationSlice);
  // const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const ShowSidebar = () => {
    if(isMobile){
    document.body.classList.toggle("body-active");
    }
  };

  // console.log(userData)
  // console.log(userData?.is_admin)


  return (
    <div className={`sideBarOuter`}>
      <div className="sideBar">
        <ul className="navlinksWrapper">
         {userData?.is_admin || JSON.parse(getStorage('userData'))?.is_admin ?
         <>
           <li className={splitLocation[1] == "admin-organizations" || splitLocation[1] == "admin-add-organizations" || splitLocation[1] == "admin-edit-organization"  ? "active" : ""} onClick={ShowSidebar}>
            <Link exact to="/admin-organizations" >
              <img src="images/menu1.svg" alt="" />
              <img src="images/menu1active.svg" alt="" className="active" />
              Organization
            </Link>
          </li>

          <li className={splitLocation[1] === "admin-users" || splitLocation[1] === "admin-add-users" || splitLocation[1] === "admin-edit-users" ? "active" : ""} onClick={ShowSidebar}>
            <Link exact to="/admin-users" >
              <img src="images/menu1.svg" alt="" />
              <img src="images/menu1active.svg" alt="" className="active" />
              Users
            </Link>
          </li>
          
          <li className={splitLocation[1] === "admin-project" || splitLocation[1] === "admin-add-project" || splitLocation[1] === "admin-edit-project" ? "active" : ""} onClick={ShowSidebar}>
            <Link exact to="/admin-project" >
              <img src="images/menu1.svg" alt="" />
              <img src="images/menu1active.svg" alt="" className="active" />
              Projects
            </Link>
          </li>

          <li className={splitLocation[1] === "admin-projectsetting" || splitLocation[1] === "admin-add-projectsetting" || splitLocation[1] === "admin-edit-projectsetting" ? "active" : ""} onClick={ShowSidebar}>
            <Link exact to="/admin-projectsetting" >
              <img src="images/menu1.svg" alt="" />
              <img src="images/menu1active.svg" alt="" className="active" />
              Project Setting
            </Link>
          </li>

          <li className={splitLocation[1] === "admin-testsuite" || splitLocation[1] === "admin-add-testsuite" || splitLocation[1] === "admin-edit-testsuite" ? "active" : ""} onClick={ShowSidebar}>
            <Link exact to="/admin-testsuite" >
              <img src="images/menu1.svg" alt="" />
              <img src="images/menu1active.svg" alt="" className="active" />
                Test Suite
            </Link>
          </li>

          <li className={splitLocation[1] === "admin-testcases" ? "active" : ""} onClick={ShowSidebar}>
            <Link exact to="/admin-testcases" >
              <img src="images/menu1.svg" alt="" />
              <img src="images/menu1active.svg" alt="" className="active" />
                Test Case
            </Link>
          </li>

          <li className={splitLocation[1] === "analytics" ? "active" : ""} onClick={ShowSidebar}>
            {/* onClick={ShowSidebar} */}
            <Link to="/analytics" >
              {/* <img src="images/menu3.svg" alt="" />
              <img src="images/menu3active.svg" alt="" className="active" /> */}
              <img src="images/menu1.svg" alt="" />
              <img src="images/menu1active.svg" alt="" className="active" />
      
              Analytics
            </Link>
          </li>




         </>:
         <>
          <li className={splitLocation[1] === "" ? "active" : ""} onClick={ShowSidebar}>
            <Link exact to="/" >
              <img src="images/menu1.svg" alt="" />
              <img src="images/menu1active.svg" alt="" className="active" />
              Project
            </Link>
          </li>

          <li className={splitLocation[1] === "test-suites" ? "active" : ""} onClick={ShowSidebar}>
          {/* onClick={ShowSidebar} */}
            <Link >
              <img src="images/menu2.svg" alt="" />
              <img src="images/menu2active.svg" alt="" className="active" />
              Test Suites
            </Link>
          </li>

         
          </>
        }
        </ul>
        {/* <p className="copyRight">Copyright. Spotline Inc.</p> */}
      </div>
    </div>
  );
}

export default Sidebar;
