import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiHelperFunction } from "../helpers/apiHelper";
import { useSelector, useDispatch } from "react-redux";

export const fetchOrganization = createAsyncThunk(
    "organization/fetchOrganization",
    async (formData) => {
      //console.log("gs",formData)
      const response = await ApiHelperFunction({
        urlPath: "api/organisation/list/pagination",
        method: "post",
        formData: formData,
      });
      let resData = response?.data;
      return resData;
    }
);
export const fetchSingleOrganization = createAsyncThunk(
    "organization/fetchSingleOrganization",
    async (id) => {
    // console.log("gs",id)
      const response = await ApiHelperFunction({
        urlPath: `api/organisation/details/${id}`,
        method: "get",
        // formData: formData,
      });
      let resData = response?.data;
      return resData;
    }
);
export const createOrganization = createAsyncThunk(
    "organization/createOrganization",
    async (formData) => {
        // console.log("gs",formData)
      const response = await ApiHelperFunction({
        urlPath: "api/organisation/insert",
        method: "post",
        formData: formData,
      });
      let resData = response?.data;
      return resData;
    }
);
export const updateOrganization = createAsyncThunk(
    "organization/updateOrganization",
    async (data) => {
      const response = await ApiHelperFunction({
        urlPath: `api/organisation/update/${data?.id}`,
        method: "post",
        formData: data,
      });
      let resData = response?.data;
      return resData;
    }
);
export const deleteOrganization = createAsyncThunk(
    "organization/deleteOrganization",
    async (data) => {
      const response = await ApiHelperFunction({
        urlPath: `api/organisation/delete`,
        method: "post",
        formData: data,
      });
      let resData = response?.data;
      return resData;
    }
);
const initialState = {
    status: "idle",
    organizationList: [],
    totalPage:"",
    currantPage:"",
    singleOrganization:[],
    organizationErrorMsg: "",
    createOrganizationMsg:"",
    createOrganizationErrMsg:"",
    singleorganizationErrorMsg:"",
    updateOrganizationMsg:"",
    updateOrganizationErrMsg:"",
    deleteOrganizetionMsg:"",
};

export const mainSlice = createSlice({
    name: "organization",
    initialState,
    reducers: {
      removeAll: (state) => {
        state = initialState;
      },
      removeorganizationErrMsg: (state)=>{
        state.organizationErrorMsg="";
      },
      removesingleorganizationErrMsg: (state)=>{
        state.singleorganizationErrorMsg="";
      },

      removeorganizationcreateMsg: (state)=>{
        state.createOrganizationMsg="";
      },

      removeorganizationcreateErrMsg: (state)=>{
        state.createOrganizationErrMsg="";
      },

      removeorganizationupdateMsg: (state)=>{
        state.updateOrganizationMsg="";
      },

      removeorganizationupdateErrMsg: (state)=>{
        state.updateOrganizationErrMsg="";
      },
      removeorganizationdeleteMsg: (state)=>{
        state.deleteOrganizetionMsg="";
      },
    },
extraReducers: (builder) => {
      builder
        .addCase(fetchOrganization.pending, (state) => {
          state.status = "loading";
        })
        .addCase(fetchOrganization.fulfilled, (state, action) => {
          state.status = "idle";
          // console.log(action.payload)

          if (action.payload != undefined) {
            if (action.payload.status === 200) {
              state.organizationList = action.payload.data.docs;
              state.totalPage = action.payload.data.pages;
              state.currantPage = action.payload.data.page;
            } else {
              state.organizationErrorMsg = action.payload.message;
            }
          } else {
            state.organizationErrorMsg = "Something went to wrong !";
          }
        })

        .addCase(fetchSingleOrganization.pending, (state) => {
            state.status = "loading";
          })
        .addCase(fetchSingleOrganization.fulfilled, (state, action) => {
            state.status = "idle";
            if (action.payload != undefined) {
              if (action.payload.status === 200) {
                state.singleOrganization = action.payload.data;
              } else {
                state.singleorganizationErrorMsg = action.payload.message;
              }
            } else {
              state.singleorganizationErrorMsg = "Something went to wrong !";
            }
        })

        .addCase(createOrganization.pending, (state) => {
            state.status = "loading";
          })
          .addCase(createOrganization.fulfilled, (state, action) => {
            state.status = "idle";
            if (action.payload != undefined) {
              if (action.payload.status === 200) {
                state.createOrganizationMsg = action.payload.message;
              } else {
                state.createOrganizationErrMsg = action.payload.message;
              }
            } else {
              state.createOrganizationErrMsg = "Something went to wrong !";
            }
        })
        .addCase(updateOrganization.pending, (state) => {
            state.status = "loading";
          })
          .addCase(updateOrganization.fulfilled, (state, action) => {
            state.status = "idle";
            if (action.payload != undefined) {
              if (action.payload.status === 200) {
                state.updateOrganizationMsg = action.payload.message;
              } else {
                state.updateOrganizationErrMsg = action.payload.message;
              }
            } else {
              state.updateOrganizationErrMsg = "Something went to wrong !";
            }
        })
        .addCase(deleteOrganization.pending, (state) => {
            state.status = "loading";
          })
        .addCase(deleteOrganization.fulfilled, (state, action) => {
            state.status = "idle";

            // console.log("jjjjjjjj",action.payload)
            if (action.payload != undefined) {
              if (action.payload.status === 200) {
                state.deleteOrganizetionMsg = action.payload.message;
                state.organizationList = action.payload.data.docs;
                state.totalPage = action.payload.data.pages;
                state.currantPage = action.payload.data.page;
              } else {
                state.deleteOrganizetionMsg = action.payload.message;
              }
            } else {
              state.deleteOrganizetionMsg = "Something went to wrong !";
            }
        })
    },
  });

export const { removeAll, removeorganizationErrMsg,removeorganizationcreateErrMsg,removeorganizationcreateMsg,removesingleorganizationErrMsg,removeorganizationupdateMsg,removeorganizationupdateErrMsg,removeorganizationdeleteMsg} = mainSlice.actions;
export default mainSlice.reducer;