import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiHelperFunction } from "../helpers/apiHelper";
import { useSelector, useDispatch } from "react-redux";

export const fetchProjectSetting = createAsyncThunk(
    "prjectSetting/fetchProjectsetting",
    async (formData) => {
      const response = await ApiHelperFunction({
        urlPath: "api/project_settings/list-advance",
        method: "post",
        formData: formData,
      });
      let resData = response?.data;
      return resData;
    }
);

export const deleteProjectSetting = createAsyncThunk(
    "prjectSetting/deleteProjectSetting",
    async (formData) => {
      const response = await ApiHelperFunction({
        urlPath: `api/project_settings/delete/${formData?.id}`,
        method: "post",
        formData: formData,
      });
      let resData = response?.data;
      return resData;
    }
);

export const createProjectSetting = createAsyncThunk(
    "prjectSetting/createProjectSetting",
    async (data) => {
      const response = await ApiHelperFunction({
        urlPath: `api/project_settings/insert`,
        method: "post",
        formData: data,
      });
      let resData = response?.data;
      return resData;
    }
);

export const fetchSingleProjectSetting = createAsyncThunk(
  "prjectSetting/fetchSingleProjectSetting",
  async (id) => {
    const response = await ApiHelperFunction({
      urlPath: `api/project_settings/details/${id}`,
      method: "get",
      // formData: data,
    });
    let resData = response?.data;
    return resData;
  }
);

export const updateProjectSetting = createAsyncThunk(
  "prjectSetting/updateProjectSetting",
  async (formData) => {
    // console.log(...formData)
    const response = await ApiHelperFunction({
      urlPath: `api/project_settings/update/${formData?.get("id")}`,
      method: "post",
      formData: formData,
    });
    let resData = response?.data;
    return resData;
  }
);


const initialState = {
    status: "idle",
    projectSettingList: [],
    totalPage:"",
    currantPage:"",
    errorMsg:"",
    projectSettingDeleteMsg:"",
    createProjectSettingMsg:"",
    createProjectSettingErrMsg:"",
    singleProjectSetting:"",
    updateProjectSettingMsg:"",
    updateProjectSettingErrMsg:"",
};

export const mainSlice = createSlice({
    name: "projectSetting",
    initialState,
    reducers: {
      removeAll: (state) => {
        state = initialState;
      },
      removeProjectSettingDeleteMsg: (state) => {
        state.projectSettingDeleteMsg = "";
      },
      removeCreateProjectSettingMsg: (state) => {
        state.createProjectSettingMsg = "";
      },
      removeCreateProjectSettingErrMsg: (state) => {
        state.createProjectSettingErrMsg = "";
      },
      removeUpdateProjectSettingMsg: (state) => {
        state.updateProjectSettingMsg = "";
      },
      removeUpdateProjectSettingErrMsg: (state) => {
        state.updateProjectSettingErrMsg = "";
      },
    },
extraReducers: (builder) => {
      builder
        .addCase(fetchProjectSetting.pending, (state) => {
            state.status = "loading";
          })
        .addCase(fetchProjectSetting.fulfilled, (state, action) => {
            state.status = "idle";
            if (action.payload != undefined) {
              if (action.payload.status === 200) {
                    state.projectSettingList = action.payload.data.docs;
                    state.totalPage = action.payload.data.pages;
                    state.currantPage = action.payload.data.page;
              } else {
                    state.errorMsg = action.payload.message;
              }
            } else {
              state.errorMsg = "Something went to wrong !";
            }
        })

        .addCase(deleteProjectSetting.pending, (state) => {
            state.status = "loading";
          })
        .addCase(deleteProjectSetting.fulfilled, (state, action) => {
            state.status = "idle";
            // console.log(action.payload)
            if (action.payload != undefined) {
              if (action.payload.status === 200) {
                state.projectSettingList = action.payload.data.docs;
                state.totalPage = action.payload.data.pages;
                state.currantPage = action.payload.data.page;
                    state.projectSettingDeleteMsg = action.payload.message;
              } else {
                    state.errorMsg = action.payload.message;
              }
            } else {
              state.errorMsg = "Something went to wrong !";
            }
        })


      .addCase(fetchSingleProjectSetting.pending, (state) => {
          state.status = "loading";
      })
      .addCase(fetchSingleProjectSetting.fulfilled, (state, action) => {
          state.status = "idle";
          // console.log(action.payload)
          if (action.payload != undefined) {
            if (action.payload.status === 200) {
                  state.singleProjectSetting = action.payload.data;
            } else {
                  state.errorMsg = action.payload.message;
            }
          } else {
            state.errorMsg = "Something went to wrong !";
          }
      })

        
      .addCase(createProjectSetting.pending, (state) => {
          state.status = "loading";
        })
      .addCase(createProjectSetting.fulfilled, (state, action) => {
          state.status = "idle";
          // console.log(action.payload)
          if (action.payload != undefined) {
            if (action.payload.status === 200) {
              state.createProjectSettingMsg = action.payload.message;
            } else {
              state.createProjectSettingErrMsg = action.payload.message;
            }
          } else {
            state.createProjectSettingErrMsg = "Something went to wrong !";
          }
      })
      
    .addCase(updateProjectSetting.pending, (state) => {
        state.status = "loading";
      })
    .addCase(updateProjectSetting.fulfilled, (state, action) => {
        state.status = "idle";
        // console.log(action.payload)
        if (action.payload != undefined) {
          if (action.payload.status === 200) {
            state.updateProjectSettingMsg = action.payload.message;
          } else {
            state.updateProjectSettingErrMsg = action.payload.message;
          }
        } else {
          state.updateProjectSettingErrMsg = "Something went to wrong !";
        }
    })

        
    

    },
  });

export const { removeAll,removeProjectSettingDeleteMsg,removeCreateProjectSettingMsg,removeCreateProjectSettingErrMsg,removeUpdateProjectSettingMsg,removeUpdateProjectSettingErrMsg } = mainSlice.actions;
export default mainSlice.reducer;

