import "./Organization.scss";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import {
  fetchOrganization,
  removeorganizationErrMsg,
  removeorganizationdeleteMsg,
  deleteOrganization,
} from "../../../Redux/AdminOrganizationSlice";
import { toast } from "react-toastify";
import Skeleton from '@mui/material/Skeleton';

import SweetAlertComponent from "../../../Share_Module/SweetAlertComponent/SweetAlertComponent";
import ReactPaginate from "react-paginate";
export default function Organization() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isDelete, setIsdelete] = useState(false);
  const [organizationName, setOrganizationName] = useState("");
  const [organizationId, setOrganizationId] = useState("");
  const [pageCount, setPageCount] = useState(0);
  const [currantP, setCurrantpage] = useState(1);
  const [query, setQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sort_field, setSotFieldName] = useState("org_name");

  const {
    status,
    organizationList,
    organizationErrorMsg,
    deleteOrganizetionMsg,
    totalPage,
    currantPage,
  } = useSelector((state) => state?.AdminOrganizationSlice);


  useEffect(() => {
    dispatch(fetchOrganization(
      {
        page: currantP,
        query: query,
        sort_field: sort_field,
        sort_order: sortOrder,
      }
    ));
  }, [dispatch]);


  useEffect(() => {
    if (totalPage) {
      setPageCount(totalPage);
    }

    if (currantPage) {
      setCurrantpage(currantPage);
    }
  }, [totalPage, currantPage]);
  const deleteorganization = (name, id) => {
    setOrganizationName(name);
    setOrganizationId(id);
    setIsdelete(true);
  };

  const handleCloneConfirm = () => {
    if (organizationId !== "") {
      dispatch(deleteOrganization({ 
        
        id: organizationId, 
        page: currantP,
        sort_field: sort_field,
        sort_order: sortOrder 
      
      }));
    }
    setOrganizationId("");
    setOrganizationName("");
    setIsdelete(false);
  };

  useEffect(() => {
    if (deleteOrganizetionMsg !== "") {
      toast.success(deleteOrganizetionMsg);
      dispatch(removeorganizationdeleteMsg());
      // dispatch(fetchOrganization({page:currantP,query:query}));
    }
  }, [dispatch, deleteOrganizetionMsg]);


  const handlePageClick = ({ selected }) => {
    setCurrantpage(selected + 1);
    dispatch(fetchOrganization({ page: selected + 1, query: query,sort_field: sort_field, sort_order: sortOrder }));

  
  };

  const handleSearch = () => {
    if (query == "") {
      setCurrantpage(currantPage);
      dispatch(fetchOrganization({ page: currantPage, query: query,sort_field: sort_field,
        sort_order: sortOrder, }));

    
      
    } else {
      setCurrantpage(1);

      dispatch(fetchOrganization({ page: 1, query: query,sort_field: sort_field,
        sort_order: sortOrder, }));
    }
  };

  const short = (sort_field1) => {
    setSotFieldName(sort_field1)
    if (sortOrder === "asc") {
      setSortOrder("desc");
      dispatch(
        fetchOrganization({
          page: 1,
          query: query,
          sort_field: sort_field1,
          sort_order: "desc",
        })
      );
    } else {
      setSortOrder("asc");
      dispatch(
        fetchOrganization({
          page: 1,
          query: query,
          sort_field: sort_field1,
          sort_order: "asc",
        })
      );
    }
  };


  return (
    <div>
      <div className="addbtn">
        <Link to="admin-add-organizations" className="btn">
          Add Organization
        </Link>
      </div>
      <div className="searchFilter">
        <div className="searchBar">
          <input
            type="text"
            placeholder="Search"
            value={query}
            onChange={(e) => {
              setQuery(e?.target?.value);
            }}
            onKeyPress={(event) => event.key === "Enter" && handleSearch()}
          />
          <button
            type="button"
            className="btn"
            onClick={(event) => handleSearch()}
          >
            <img src="images/search.svg" alt="" />
          </button>
        </div>

        {/* <div className="filtersec">
          <select name="" id="">
            <option value="">Option 1</option>
            <option value="">Option 2</option>
            <option value="">Option 3</option>
          </select>
        </div> */}
      </div>
      <div className="dataSection">
        <div className="table-responsive">
          <table>
            <thead>
              <tr>
                <th>
                  Organization{" "}
                  <button type="button" onClick={() => short("org_name")}>
                    <img src="images/sorting.svg" alt="" />
                  </button>
                </th>
                <th>
                  Country{" "}
                  {/* <span>
                    <i
                      className={`fa fa-arrow-up cp`}
                      // ${direction !== "asc" &&
                      //   "text-secondary"}`}
                      style={{fontSize:"10px"}}
                      aria-hidden="true"
                    />
                    <i
                      className={`fa fa-arrow-down cp text-secondary`}
                      // ${direction !== "desc" &&
                      //   "text-secondary"}`}
                      style={{fontSize:"10px"}}
                      aria-hidden="true"
                    />
                  </span> */}
                  <button type="button" onClick={() => short("country")}>
                    <img src="images/sorting.svg" alt="" />
                  </button>
                </th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {status === "loading" ? (
                [1,1,1,1,1,1]?.map((obj,i)=>
                <tr className="" key={i}>
                <td >
                  <Skeleton variant="text" width={"99%"} height={30} />
                </td>
                <td >
                  <Skeleton variant="text" width={"99%"} height={30} />
                </td>
                <td >
                  <Skeleton variant="text" width={"99%"} height={30} />
                </td>
               
              </tr>
               )
              ) : organizationList?.length === 0 ? (
                <td colSpan="3">Organization not found!</td>
              ) : (
                organizationList
                  ?.filter((gs) => gs.isDeleted === false)
                  ?.map((organizationlist, key) => (
                    <tr>
                      <td>{organizationlist?.org_name}</td>
                      <td>{organizationlist?.country}</td>
                      <td>
                        {" "}
                        <Link
                          to={`admin-edit-organization?id=${organizationlist?._id}`}
                        >
                          <i
                            className="fas fa-edit cursor-pointer"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Edit Organization"
                          ></i>
                        </Link>{" "}
                        &nbsp;&nbsp;{" "}
                        <i
                          className="fas fa-trash-alt cursor-pointer"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Delete Organization"
                          onClick={() => {
                            deleteorganization(
                              organizationlist?.org_name,
                              organizationlist?._id
                            );
                          }}
                        ></i>{" "}
                      </td>
                    </tr>
                  ))
              )}
            </tbody>
          </table>
        </div>
        <nav className="paginations" aria-label="Page navigation example">
          {/* <ul class="pagination">
            <li class="page-item">
              <a class="page-link" href="#">
                Previous
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                1
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                2
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                3
              </a>
            </li>
            <li class="page-item">
              <a class="page-link" href="#">
                Next
              </a>
            </li>
          </ul> */}

          <ReactPaginate
            breakLabel="..."
            nextLabel="Next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            pageCount={pageCount}
            containerClassName={"paginationBttns"}
            previousLinkClassName={"previousBttn"}
            nextLinkClassName={"nextBttn"}
            disabledClassName={"paginationDisabled"}
            activeClassName={"paginationActive"}
            previousLabel="Previous"
            renderOnZeroPageCount={null}
            forcePage={currantPage - 1}
          />
        </nav>
      </div>

      {isDelete && (
        <SweetAlertComponent
          confirm={handleCloneConfirm}
          cancle={() => setIsdelete(false)}
          title={"Are you sure?"}
          subtitle={`you want to Delete the Organization ${organizationName}`}
          type="danger"
        />
      )}
    </div>
  );
}
