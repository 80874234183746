import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApiHelperFunction } from "../helpers/apiHelper";
import { useSelector, useDispatch } from "react-redux";
export const createTestSuite = createAsyncThunk(
  "project/createTestSuite",
  async (formData) => {
    // console.log(...formData)
    const response = await ApiHelperFunction({
      urlPath: "api/test_suite/insert",
      method: "post",
      formData: formData,
    });
    let resData = response?.data;
    return resData;
  }
);

export const fetchTestSuite = createAsyncThunk(
  "project/fetchTestSuite",
  async (formData) => {
    // console.log(formData)
    const response = await ApiHelperFunction({
      urlPath: "api/test_suite/list-advance",
      method: "post",
      formData: formData,
    });
    let resData = response?.data;
    return resData;
  }
);

export const updateTestsuite = createAsyncThunk(
  "project/updateTestsuite",
  async (formData) => {
    console.log(formData);
    const response = await ApiHelperFunction({
      urlPath: `api/test_suite/update/${formData?.id}`,
      method: "post",
      formData: formData,
    });
    let resData = response?.data;
    return resData;
  }
);

export const singleTestsuiteFetch = createAsyncThunk(
  "project/singleTestsuiteFetch",
  async (id) => {
    const response = await ApiHelperFunction({
      urlPath: `api/test_suite/details/${id}`,
      method: "get",
    });
    let resData = response?.data;

    console.log("data", resData);
    return resData;
  }
);

export const deleteTestSuite = createAsyncThunk(
  "project/deleteTestSuite",
  async (formData) => {
    const response = await ApiHelperFunction({
      urlPath: `api/test_suite/delete`,
      method: "post",
      formData: formData,
    });
    let resData = response?.data;
    return resData;
  }
);

const initialState = {
  status: "idle",
  createTestSuiteMsg: "",
  createTestSuiteErrMsg: "",
  TestSuiteList: [],
  totalPage: "",
  currantPage: "",
  updateTestSuiteMsg: "",
  updateTestSuiteErrMsg: "",
  singleTestSuite: "",
  deleteTestCaseMsg: "",
};
export const mainSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    removeAll: (state) => {
      state = initialState;
    },
    removeCreateTestSuiteMsg: (state) => {
      state.createTestSuiteMsg = "";
    },
    removeCreateTestSuiteErrMsg: (state) => {
      state.createTestSuiteErrMsg = "";
    },
    removeUpdateTestSuiteMsg: (state) => {
      state.updateTestSuiteMsg = "";
    },
    removeUpdateTestSuiteErrMsg: (state) => {
      state.updateTestSuiteErrMsg = "";
    },
    removeTestCaseMsg: (state) => {
      state.deleteTestCaseMsg = "";
    },

    
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchTestSuite.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTestSuite.fulfilled, (state, action) => {
        state.status = "idle";
        console.log(action.payload);
        if (action.payload != undefined) {
          if (action.payload.status === 200) {
            state.TestSuiteList = action?.payload?.data?.docs;
            state.totalPage = action.payload.data?.pages;
            state.currantPage = action.payload.data?.page;
          } else {
            state.createTestSuiteErrMsg = action.payload.message;
          }
        } else {
          state.createTestSuiteErrMsg = "Something went to wrong !";
        }
      })
      .addCase(createTestSuite.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createTestSuite.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload != undefined) {
          if (action.payload.status === 200) {
            state.createTestSuiteMsg = action.payload.message;
          } else {
            state.createTestSuiteErrMsg = action.payload.message;
          }
        } else {
          state.createTestSuiteErrMsg = "Something went to wrong !";
        }
      })
      .addCase(updateTestsuite.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateTestsuite.fulfilled, (state, action) => {
        state.status = "idle";
        console.log(action.payload);
        if (action.payload != undefined) {
          if (action.payload.status === 200) {
            state.updateTestSuiteMsg = action.payload.message;
            state.TestSuiteList = action?.payload?.dataWithPage?.docs;
            state.totalPage = action.payload.dataWithPage?.pages;
            state.currantPage = action.payload.dataWithPage?.page;
          } else {
            state.updateTestSuiteErrMsg = action.payload.message;
          }
        } else {
          state.updateTestSuiteErrMsg = "Something went to wrong !";
        }
      })

      .addCase(singleTestsuiteFetch.pending, (state) => {
        state.status = "loading";
      })
      .addCase(singleTestsuiteFetch.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload != undefined) {
          if (action.payload.status === 200) {
            state.singleTestSuite = action.payload.data;
          } else {
            state.updateTestSuiteErrMsg = action.payload.message;
          }
        } else {
          state.updateTestSuiteErrMsg = "Something went to wrong !";
        }
      })
      .addCase(deleteTestSuite.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteTestSuite.fulfilled, (state, action) => {
        state.status = "idle";
        if (action.payload != undefined) {
          if (action.payload.status === 200) {
            state.deleteTestCaseMsg = action.payload.message;
            state.TestSuiteList = action?.payload?.data?.docs;
            state.totalPage = action.payload.data?.pages;
            state.currantPage = action.payload.data?.page;
          } else {
            state.updateTestSuiteErrMsg = action.payload.message;
          }
        } else {
          state.updateTestSuiteErrMsg = "Something went to wrong !";
        }
      });
  },
});
export const {
  removeAll,
  removeCreateTestSuiteMsg,
  removeCreateTestSuiteErrMsg,
  removeUpdateTestSuiteMsg,
  removeUpdateTestSuiteErrMsg,
  removeTestCaseMsg
} = mainSlice.actions;
export default mainSlice.reducer;
