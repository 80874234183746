


import React from 'react';
import { Doughnut } from 'react-chartjs-2';

const data = {
//   labels: ['Red', 'Blue', 'Yellow', 'Green'],
  datasets: [
    {
      label: '# of Votes',
      data: [40, 30, 20, 10, ],
      backgroundColor: [
        'rgba(95,189,243,0.8)',
        'rgba(45,198,148,0.8)',
        'rgba(236,96,96, 0.8)',
        'rgba(244,145,13,0.8)',
      
      ],
      borderColor: [
        'rgba(95,189,243,1)',
        'rgba(45,198,148,1)',
        'rgba(236,96,96, 1)',
        'rgba(244,145,13,1)',
        
      ],
      borderWidth: 1,
    },
  ],
};

const options= {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: ''
      }
    }
  }

const PieChart = () => (
  <>
    <Doughnut data={data} options={options}/>
  </>
);

export default PieChart;